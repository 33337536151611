import {dayjs as moment} from 'dayjs';
import objecthash from 'objecthash';
import Settings from '../Settings';
import $ from 'jquery';
import * as localforage from "localforage";
import EventDispatcher from "../Utility/EventDispatcher";
import * as Sentry from "@sentry/react";

const Api = {
    me: [],
    token: null,
    device_id: null,
    post: function (url, parameters, callback, errorCallback, progressCallback) {
        this.sendRequest('POST', url, parameters, callback, errorCallback, progressCallback);
    },
    postJson: function (url, parameters, callback, errorCallback, progressCallback) {
        this.sendRequest('POST', url, parameters, callback, errorCallback, progressCallback, 'application/json');
    },
    get: function (url, parameters, callback, errorCallback, progressCallback) {
        this.sendRequest('GET', url, parameters, callback, errorCallback, progressCallback);
    },
    getCached: function (url, parameters, callback, errorCallback, progressCallback) {
        this.requestCached('GET', url, parameters, callback, errorCallback, progressCallback);
    },
    clearCacheEntry: function (method, url, parameters) {
        var cacheKey = this.generateCacheKey(method, url, parameters);
        this.clearCache(cacheKey);
    },
    requestCached: function (method, url, parameters, callback, errorCallback, progressCallback, ttl) {
        var cacheKey = this.generateCacheKey(method, url, parameters);
        if (!ttl) {
            ttl = 1800;
        }
        var item = this.localStorage.load(cacheKey);
        if (item) {
            if (moment(item.expires).isBefore(moment())) {
                this.clearCache(cacheKey);
                item = null;
            } else {
                callback(item.data);
            }
        }
        var keyLength = item ? item.data.length : 0;
        if (typeof item === 'object' && item !== null) {
            keyLength = Object.keys(item.data).length;
        }
        if (!item || !keyLength) {
            this.sendRequest(method, url, parameters, function (data) {
                var cacheObject = {
                    expires: moment().add(ttl, 'seconds').format(),
                    data: data
                };
                this.localStorage.save(cacheKey, cacheObject);
                callback(data);
            }.bind(this), errorCallback, progressCallback);
        }
    },
    clearCache: function (method, url, parameters) {
        this.localStorage.remove(this.generateCacheKey(method, url, parameters));
    },
    clearAllCache: function () {
        this.localStorage.clear();
    },
    generateCacheKey: function (method, url, parameters) {
        return objecthash({method: method.toUpperCase(), url: url, parameters: parameters});
    },
    localStorage: {
        remove: function (key) {
            if (this.isLocalStorageSupported() === false) {
                return null;
            }
            localStorage.removeItem(key);
        },
        save: function (key, value) {
            if (this.isLocalStorageSupported() === false) {
                return null;
            }
            localStorage.setItem(key, JSON.stringify(value));
        },
        load: function (key, defaultValue) {
            if (this.isLocalStorageSupported() === false) {
                return null;
            }
            var value = localStorage.getItem(key);
            if (!defaultValue) {
                defaultValue = null;
            }
            if (value == null || value === 'null') {
                return defaultValue;
            }
            return JSON.parse(value);
        },
        clear: function () {
            if (this.isLocalStorageSupported() === true) {
                localStorage.clear();
            }
        },
        garbageCollection: function () {
            if (this.isLocalStorageSupported()) {
                for (var x in localStorage) {
                    if (typeof localStorage[x] === 'string') {
                        var item = JSON.parse(localStorage[x]);
                        if (item && moment(item.expires).isBefore(moment())) {
                            this.remove(x);
                        }
                    }
                }
            }
        },
        isLocalStorageSupported: function () {
            var testKey = 'test', storage = localStorage;
            try {
                storage.setItem(testKey, '1');
                storage.removeItem(testKey);
                return true;
            } catch (error) {
                return false;
            }
        }
    },
    loadPermissions: function (callback) {
        this.getCached('rest/me/permissions', {}, function (data) {
            this.me = data.data;
            if (callback) {
                callback();
            }
        }.bind(this));
    },
    hasPermission: function (name) {
        var permissions = this.me.permissions;
        if (permissions.length === 0) {
            return false;
        }
        var permission = permissions.data.filter((p) => {
            return p.name === name;
        });

        if (permission.length === 0) {
            return false;
        }

        return permission[0].allowed;
    },
    loadBars: function (callback, only_current_user, include, ignoreCache) {
        var formData = {open: true};
        if (include) {
            formData.include = include;
        }
        if (only_current_user === true) {
            formData.only_current_user = true;
        }
        if (!ignoreCache) {
            this.getCached('rest/bars', formData, function (data) {
                if (callback) {
                    callback(data.data);
                }
            });
        } else {
            this.get('rest/bars', formData, function (data) {
                if (callback) {
                    callback(data.data);
                }
            });
        }
    },
    getBrands: function () {
        return [
            {id: 1, name: 'Revolution'},
            {id: 2, name: 'Revolucion de Cuba'}
        ]
    },
    showError: function (error, title) {
        //$.jGrowl(error, {header: title, position: 'top-right', theme: 'error', 'sticky': false, life: '8000'});
    },
    showNotification: function (message, title) {
        //$.jGrowl(message, {header: title, position: 'top-right', theme: 'success', 'sticky': false, life: '8000'});
    },
    currencyFormatter: function () {
        if (!this.currency_formatter) {
            this.currency_formatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2
            })
        }
        return this.currency_formatter;
    },
    sendRequest: function (method, url, parameters, callback, errorCallback, progressCallback, contentType) {
        this.clearCache(method, url, parameters);
        if (!contentType) {
            contentType = 'application/x-www-form-urlencoded';
        }
        if (contentType === 'application/json') {
            parameters = JSON.stringify(parameters);
        }
        if (url.substr(0, 4) !== 'http') {
            url = Settings.endpoint + url;
        }
        $.ajax({
            url: url,
            dataType: 'json',
            contentType: contentType,
            data: parameters,
            type: method,
            cache: false,
            success: function (data) {
                this.progressCallback = null;
                if (callback) {
                    callback(data);
                }

            }.bind(this),
            xhr: function () {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload && progressCallback) {
                    myXhr.upload.addEventListener('progress', progressCallback, false);
                }
                return myXhr;
            },
            beforeSend: (xhr) => {
                xhr.setRequestHeader('X-User-Agent', navigator.userAgent);
                xhr.setRequestHeader('X-Device-ID', this.device_id);
                xhr.setRequestHeader('X-Release-Timestamp', `${process.env.REACT_APP_RELEASE}`);
                xhr.setRequestHeader('X-Device-Token', this.token);
                if (window.performance && window.performance.memory) {
                    xhr.setRequestHeader('X-Memory-Usage', JSON.stringify({
                        jsHeapSizeLimit: window.performance.memory.jsHeapSizeLimit,
                        totalJSHeapSize: window.performance.memory.totalJSHeapSize,
                        usedJSHeapSize: window.performance.memory.usedJSHeapSize
                    }));
                } else {
                    xhr.setRequestHeader('X-Memory-Usage', JSON.stringify({
                        jsHeapSizeLimit: 0,
                        totalJSHeapSize: 0,
                        usedJSHeapSize: 0
                    }));
                }
            },
            error: (xhr, status, err) => {
                var data = null;
                if (xhr.responseText) {
                    try {
                        data = JSON.parse(xhr.responseText);
                    } catch (error) {
                        EventDispatcher.dispatch('show-error', 'Application error');
                        Sentry.setExtra('response', xhr.responseText);
                        Sentry.captureException(error);
                        if (typeof errorCallback !== 'undefined') {
                            errorCallback(xhr, status, err, data, {response: xhr.responseText});
                        }
                        return;
                    }
                    var errorMessage = '';
                    if (data.errors) {
                        if (data.errors.fields) {
                            var messages = data.errors.fields.map(function (field) {
                                return field.error;
                            });

                            errorMessage = messages.join('<br />');
                        } else if (data.errors.message) {
                            errorMessage = data.errors.message;
                        } else {
                            errorMessage = err.toString();
                        }
                    } else {
                        errorMessage = err.toString();
                    }
                    Api.showError(errorMessage, 'ERROR');
                    if (typeof errorCallback !== 'undefined') {
                        errorCallback(xhr, status, err, data, errorMessage);
                    }
                }
            }
        });
    },
};

const LocalStorage = {
    get: async (key, callback) => {
        return localforage.getItem(key, callback);
    },
    set: (key, value) => {
        return localforage.setItem(key, value);
    }
}

export {LocalStorage};
export default Api;