import React, {useState} from 'react';

const Tabs = (props) => {
    let tabs = [];
    React.Children.forEach(props.children, function (child) {
        tabs.push(child);
    });
    const [tab, setTab] = useState(props.initial_tab);
    return <div className="partypro-tabs-holder">
        <ul className="partypro-tabs hidden-print">
            {tabs.map((child) => {
                if (child) {
                    let className = tab === child.props.id ? 'active larger partypro-tab-link' : 'larger partypro-tab-link';
                    if (child.props.float === 'right') {
                        className = className + ' pull-right';
                    }
                    return <li
                        className={className} key={child.props.id}>
                        <p>
                            <a href="#"
                               onClick={(e) => {
                                   e.preventDefault();
                                   if (props.handleChange) {
                                       props.handleChange(child.props.id);
                                   }
                                   setTab(child.props.id);
                               }}>{child.props.title}</a>
                        </p>
                    </li>
                }
                return null;
            })}
        </ul>
        <div className="clearfix"/>
        <div className="tab">
            {tabs.map((child) => {
                if (child && child.props.id === tab) {
                    return <div key={child.props.id} className="tab-content">
                            {child}
                         </div>;
                }
                return null;
            })}
        </div>
    </div>
}

export default Tabs;