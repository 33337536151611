import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {AppState} from "../Context/AppContext";
import FormElementText from "../Form/Element/Text";
import {ModalHelper} from "../Utility/Modal";
import Api from "../Api/Api";

const EditDevice = () => {
    const [state] = useContext(AppState);
    const [settings, setSettings] = useState({
        device_name: state.device.name
    });
    const settingsRef = useRef(settings);
    settingsRef.current = settings;
    const handleSave = useCallback(() => {
        Api.post('rest/res/device-name', {name: settingsRef.current.device_name}, () => {
            ModalHelper.closeModal();
        });
    }, [settingsRef]);
    useEffect(() => {
        setTimeout(() => {
            ModalHelper.addSaveButton(handleSave, 'Save');
        }, 100);
    }, [handleSave]);
    return <div>
        <FormElementText name="device_name" value={settings.device_name} onChange={(e) => {
            setSettings({...settings, device_name: e.target.value})
        }} prepend="Device Name"/>
    </div>
}

export default EditDevice;