import React, {forwardRef, useContext, useEffect, useState} from 'react';
import {AppState} from "../Context/AppContext";
import $ from 'jquery'
import 'jquery-ui'
import dayjs from "dayjs";
import FormElementBarSelect from "../Form/Element/BarSelect";
import Api from "../Api/Api";
import Chat from "../Chat/Chat";
import BookingSearch from "../Search/BookingSearch";
import {ModalHelper} from "../Utility/Modal";
import EditDevice from "../EditDevice/EditDevice";
import EventDispatcher from "../Utility/EventDispatcher";
import DatePicker from "react-datepicker";
import './Header.scss';
import cx from 'classnames';

import ('./../Css/Vendor/jquery/jquery-touch-punch');

const Header = (props) => {
    const [change, setChange] = useState(false);
    const [state, dispatch] = useContext(AppState);
    const [showChat, setShowChat] = useState(false);
    const [bar, setBar] = useState(state.bar.id);
    const [showWarnings, setShowWarnings] = useState(false);
    let session = state.active_session;
    let device = state.device;
    let min = dayjs('00:00', 'HH:mm').add(-1, 'second');
    let bus_date = dayjs(state.selected_time);
    let prev = dayjs(state.selected_time).add(-1, 'day');
    // Used jQuery to make the chat draggable
    useEffect(() => {
        $(function () {
            $("#draggable-chat").draggable({containment: '.App', cancel: '.chat .chat-history'});
        })
    }, [showChat]);

    useEffect(() => {
        const listener = EventDispatcher.subscribe('close-all-table-tips', () => {
            setShowWarnings(false);
        });
        const listener_close = EventDispatcher.subscribe('close-tables', () => {
            setShowWarnings(false);
        });
        const modal_close = EventDispatcher.subscribe('open-modal', () => {
            setShowWarnings(false);
        });
        return () => {
            EventDispatcher.unsubscribe(listener);
            EventDispatcher.unsubscribe(listener_close);
            EventDispatcher.unsubscribe(modal_close);
        }
    }, []);
    if (!session) {
        return null;
    }
    let active_warnings = state.warnings.filter((w) => {
        return state.ignored_warnings.indexOf(w.key) === -1;
    });
    const DatePickerInput = forwardRef(({value, onClick}, ref) => (
        <button onClick={onClick} className={cx('date-picker', {'not-today': state.time_travel})} ref={ref}>
            {bus_date.format('dddd Do MMMM')}
        </button>
    ));
    return <div className="header">
        {change ? <div className="bar-name change-bar">
                <div className="bar-dropdown">
                    <FormElementBarSelect name="bar_id" onChange={(e) => setBar(e.target.value)}
                                          selectedValue={bar}
                                          includeBlankOption={true} blankOptionText={' - Select Bar - '}/>
                </div>
                <div className="buttons">
                    <div className="btn-group">
                        {bar ?
                        <i className="fa fa-check" 
                        onClick={() => {
                            Api.post('rest/res/super-device/update', {bar_id: bar}, (data) => {
                                document.location.reload();
                            });
                        }}/>: null}
                        <i className="fa fa-times" onClick={() => setChange(false)}/>
                    </div>
                </div>
            </div> :
            <div className="bar-name">
                {state.offline === false ? <span className="status-indicator online"/> :<span className="status-indicator offline"/>}
                <div className="bar-and-user">
                    <span className="bar">{state.bar.bar_name}</span> 
                    <span className="user">{device.name}</span>
                </div>
                <div className="btn-group">
                    {device.super_device ? <i className="fas fa-desktop" onClick={() => setChange(true)}/> : null}
                    <i className="fas fa-pencil-alt" onClick={() => {ModalHelper.openModal('Device Settings', <EditDevice/>);}}/>
                </div>
            </div>}
        {session.type ?
            <div className="session">
                <button onClick={() => {
                    let current_date = dayjs(state.start).format('YYYY-MM-DD') + ' ' + dayjs(state.current_time).format('HH:mm');
                    dispatch({
                        type: 'SET_TIME_TRAVEL_DATE',
                        payload: dayjs(current_date).add(-1, 'day')
                    });
                }} className={cx('prev-day', {'disabled': prev.isBefore(min)})}
                        disabled={prev.isBefore(min)}>
                    <i className="fa fa-angle-left"/>
                </button>
                <DatePicker selected={bus_date.toDate()}
                            minDate={dayjs().add(-6, 'hour').toDate()}
                            onChange={(e) => {
                                dispatch({
                                    type: 'SET_TIME_TRAVEL_DATE',
                                    payload: dayjs(e)
                                });
                            }}
                            customInput={<DatePickerInput/>}/>
                <button onClick={() => {
                    let current_date = dayjs(state.start).format('YYYY-MM-DD') + ' ' + dayjs(state.current_time).format('HH:mm');
                    dispatch({
                        type: 'SET_TIME_TRAVEL_DATE',
                        payload: dayjs(current_date).add(1, 'day')
                    });
                }} className="next-day">
                    <i className="fa fa-angle-right"/>
                </button>
            </div> : null}
        {session.type ?
            <div className="wait-time btn-group">
                <i className="fas fa-sync" onClick={() => document.location.reload()}/>
                <i className="fab fa-facebook-messenger" onClick={() => setShowChat(!showChat)}>
                    {state.unread_chat > 0 ? <span>{state.unread_chat} </span> : null}
                </i>
                {showChat ? <div id="draggable-chat" className="chat-window">
                    <div className="chat-header">
                        <span className="messages-header">Team Messages</span>
                        <i className="close fas fa-times" onClick={() => setShowChat(!showChat)}></i>
                    </div>
                    <Chat/>
                </div> : null}

                <div className="warnings-holder">
                    <i  className={`fas fa-exclamation-triangle ${active_warnings.length > 0 ? 'danger' : 'disabled'}`} 
                        onClick={() => setShowWarnings(!showWarnings)}>
                    </i>
                    {showWarnings ? <div className="warnings">
                        {state.warnings.map(function (error) {
                            if (state.ignored_warnings.indexOf(error.key) > -1) {
                                return null;
                            }

                            return <div className="alert alert-danger" key={error.warning}>
                                <div className="row">
                                    <div className={(error.can_ignore || error.type === 'reservation' ? "col-md-9" : 'col-md-12')}>{error.warning}</div>
                                    {(error.can_ignore || error.type === 'reservation') &&
                                    <div className="col-md-3 warning-buttons">
                                        {error.type === 'reservation' && <button
                                            className="btn btn-success btn-xs edit"
                                            onClick={() => {
                                                setShowWarnings(false);
                                                EventDispatcher.dispatch('open-reservation', {id: error.reservation});
                                            }}> Edit
                                        </button>}

                                        {error.can_ignore &&
                                        <button className="btn btn-warning btn-xs ignore" onClick={() => {
                                            Api.post('rest/res/warnings/ignore', {
                                                key: error.key,
                                                date: dayjs(state.start).format('YYYY-MM-DD'),
                                                warning_text: error.warning
                                            });
                                        }}>Ignore
                                        </button>}
                                    </div>}
                                </div>
                            </div>
                        })}
                    </div> : null}
                </div>
            </div> : null}

        {session.type ?
            <div className="search">
                <BookingSearch/>
            </div> : null}
    </div>
}

export default Header;