import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from "underscore";
import {AppState} from "../Context/AppContext";
import $ from 'jquery';
import EventDispatcher from "../Utility/EventDispatcher";
import * as dayjs from "dayjs";
import Api from "../Api/Api";
import cx from 'classnames';
import Settings from "../Settings";

const calculateTopPosition = (pos, ratio, height, window_height, offset) => {
    var position = pos * ratio;
    var bottom = position + offset + height + 10;
    if (bottom > window_height) {
        position = position - (bottom - window_height);
    }

    return position;
}
const LayoutDetail = React.memo((props) => {
    const [state, dispatch] = useContext(AppState);
    const [showPopup, setShowPopup] = useState(false);
    const [moving, setMoving] = useState(false);
    const [table, setTable] = useState(null);
    const [top, setTop] = useState(0);
    const [visibility, setVisibility] = useState(false);
    let detail = props.detail;
    useEffect(() => {
        const listener = EventDispatcher.subscribe('close-tables', () => {
            setShowPopup(false);
        });
        return () => {
            EventDispatcher.unsubscribe(listener);
        }
    }, []);
    useEffect(() => {
        let table = _.find(state.tables, (t) => {
            return t.id === detail.table_id;
        });
        if (table) {
            setTable(table);
        } else {
            setTable(null);
        }
    }, [state.tables, detail.table_id]);
    useEffect(() => {
        if (state.move_reservation && table && table.current_reservation && state.move_reservation.reservation_id === table.current_reservation.id) {
            setMoving(true);
        } else {
            setMoving(false);
        }
    }, [state.move_reservation, table]);
    useEffect(() => {
        if (showPopup === true) {
            let level = $('.level:eq(0)');
            setTop(calculateTopPosition(detail.xpos, state.screen.ratio, $('#popup_' + detail.id).outerHeight(), level.height(), level.position().top + 10));
            setVisibility(true);
        } else {
            setVisibility(false);
        }
    }, [showPopup]);
    if (!table) {
        return <span/>
    }
    return <div className={cx('layout-detail-item', {moving: moving})} key={detail.id}>
        <div
            className={table.className} onClick={(e) => {
            e.stopPropagation();
            if (state.move_reservation) {
                Api.post('rest/res/reservation/' + state.move_reservation.reservation_id + '/tables', {
                    old_table: state.move_reservation.table_id,
                    new_table: table.id
                }, function (data) {
                    dispatch({
                        type: 'MOVE_RESERVATION',
                        payload: null
                    });
                });
            } else {
                EventDispatcher.dispatch('close-tables');
                if (!table.dummy) {
                    setShowPopup(!showPopup);
                }
            }
        }}
            id={"layout_detail_" + table.id} style={{
            width: table.width * state.screen.ratio,
            height: table.height * state.screen.ratio,
            top: detail.xpos * state.screen.ratio,
            left: detail.ypos * state.screen.ratio,
            backgroundColor: '#' + table.status.background_colour,
            color: '#' + table.status.text_colour
        }} data-id={table.id}>
            <p className="table-status-text"
               style={{color: '#' + table.status.text_colour}}>
                <span className="table-name">{table.name}</span>
                {state.time_travel && table.status.message.replace('FREE', '')}
                <span className="time-diff">{table.status.time_diff}</span>
                {table.status.show_progress ?
                    <progress max={100} value={table.status.progress ? table.status.progress : 0}/> : null}
            </p>
        </div>
        {showPopup ? <div style={{
            top: top,
            visibility: visibility ? 'visible' : 'hidden',
            left: (detail.ypos * state.screen.ratio) + table.type.data.width,
        }} className="actions" id={"popup_" + detail.id}>
            <p className="clearfix table-info">
                <span className="table-name">Table: {table.name}</span>
                <span className="table-covers">Covers: {table.seats}</span>
            </p>

            <div className="popup-status">
                <p className="popup-status-title">Table Status:</p>
                <p className="popup-status-text">{table.status.message} {table.status.time_diff}</p>
            </div>

            <div className="clearfix">
                {detail.available_walkin && !detail.dummy && !table.current_reservation && table.can_take_walk_in && !table.current_late_reservation ?
                    <button className="btn add-walk-in" onClick={() => {
                        EventDispatcher.dispatch('add-walk-in', {
                            start_time: dayjs(state.selected_time).format('YYYY-MM-DD HH:mm'),
                            tables: [detail.table_id],
                            covers: 2,
                            status_id: '20',
                        });
                    }}>
                        Add Walk-In
                    </button> : null}
                {table.current_late_reservation ? <div>
                    {table.current_late_reservation.time_late > (60 * 10) ?
                        <button className="btn btn-danger wrap-button" onClick={() => {
                            Api.post('rest/res/reservation/' + table.current_late_reservation.id + '/status', {status: Settings.status_no_show}, function (data) {

                            });
                        }}
                                style={{whiteSpace: 'break-spaces'}}>No
                            Show {table.current_late_reservation.display_name} - {dayjs(table.current_late_reservation.start_time).format('HH:mm')}</button> : null}
                    <button className="btn btn-success wrap-button" onClick={() => {
                        Api.post('rest/res/reservation/' + table.current_late_reservation.id + '/seat', {});
                    }}
                            style={{whiteSpace: 'break-spaces'}}>Seat {table.current_late_reservation.display_name} - {dayjs(table.current_late_reservation.start_time).format('HH:mm')}</button>
                </div> : null}
                {table.current_reservation && !state.time_travel ? <div>
                    <button className="btn open-reservation" data-booking-id={table.current_reservation.id}
                            onClick={() => {
                                EventDispatcher.dispatch('open-reservation', table.current_reservation);
                            }}>
                        Open Reservation
                    </button>
                    {state.move_reservation === null ?
                        <button className="btn move" onClick={() => {
                            dispatch({
                                type: 'MOVE_RESERVATION',
                                payload: {
                                    reservation_id: table.current_reservation.id,
                                    table_id: table.id
                                }
                            });
                        }}>Move
                        </button> : null}
                    <button className="btn complete-reservation" data-booking-id={table.current_reservation.id}
                            onClick={() => {
                                EventDispatcher.dispatch('close-all-table-tips');
                                Api.post('rest/res/reservation/' + table.current_reservation.id + '/status', {status: Settings.status_finished}, function (data) {

                                });
                            }}>
                        Complete Reservation
                    </button>

                    <p className="change-status">Change Status</p>
                    {state.statuses.map((s) => {
                        if (s.id >= 11 && s.id < 70) {
                            return <button className="btn"
                                           style={{backgroundColor: '#' + s.colour, color: '#' + s.text_colour}}
                                           data-booking-id={table.current_reservation.id} key={s.id}
                                           onClick={() => {
                                               EventDispatcher.dispatch('close-all-table-tips');
                                               Api.post('rest/res/reservation/' + table.current_reservation.id + '/status', {status: s.id}, function (data) {

                                               });
                                           }}>
                                {s.name}
                            </button>
                        }
                        return null;
                    })}
                </div> : <div className="table-popup-reservations">{table.reservations.map((r) => {
                    return <button onClick={(e) => {
                        e.preventDefault();
                        EventDispatcher.dispatch('open-reservation', r);
                    }} key={r.id} className="layout-detail-future-booking">
                        {r.display_name}<br/>
                        {dayjs(r.start_time).format('HH:mm')} - {dayjs(r.end_time).format('HH:mm')}<br/>
                        (<i className="fa fa-user"/> {r.covers})
                    </button>
                })}</div>}
            </div>
        </div> : null}
    </div>
});

LayoutDetail.propTypes = {
    detail: PropTypes.object.isRequired
}

export default LayoutDetail;