import React, { Fragment, useState } from 'react'
import * as dayjs from 'dayjs'
import Api from '../Api/Api'
import './BookingDetails.scss'

const BookingDetails = (props) => {
    let booking = props.booking

    const [showPreOrder, setShowPreOrder] = useState(false)
    let customer = null
    if (booking.customer) {
        customer = booking.customer.data
    }
    return <div className="booking-details">
        <table className="table">
            <tbody>
            <tr>
                <th>Booking Date</th>
                <td>{dayjs(booking.booking_date).format('DD/MM/YYYY HH:mm')}</td>
                <th>Ref Code</th>
                <td>{booking.ref_code}</td>
            </tr>
            <tr>
                <th>PBR</th>
                <td>{Api.currencyFormatter().format(booking.value)}</td>
                <th>Deposit Total</th>
                <td>{Api.currencyFormatter().format(booking.deposit_total)}</td>
            </tr>
            <tr>
                <th>Customer</th>
                <td>{customer.forename} {customer.surname}<br />{customer.email}</td>
                <th>Allergies</th>
                <td>
                    {booking.allergy_status === 1 && <div className="alert alert-success">None Declared</div>}
                    {booking.allergy_status === 2 ? <div className="alert alert-danger">Yes</div> : null}
                    {booking.allergy_status === 3 || booking.allergy_status === 4? <div className="alert alert-warning">Ask on arrival</div> : null}

                    {booking.allergy_info && <div className="alert alert-info">{booking.allergy_info}</div>}
                </td>
            </tr>
            <tr>
                <th>No Show Fee</th>
                <td>{Api.currencyFormatter().format(booking.no_show_fee)}</td>
                <th>Stored Card ?</th>
                <td>{booking.has_stored_card ? 'Yes' : 'No'}</td>
            </tr>
            {booking.special_requirements ?
              <tr>
                  <th>Special Requirements</th>
                  <td colSpan="3">{booking.special_requirements}</td>
              </tr> : null}
            {booking.options.data.length > 0 ?
              <tr>
                  <th colSpan="4">Booking Options
                      <button className={showPreOrder ? 'btn btn-default pull-right' : 'btn btn-success pull-right'}
                              onClick={() => {
                                  if (showPreOrder) {
                                      setShowPreOrder(false)
                                      return
                                  }
                                  setShowPreOrder(true)
                              }}>{showPreOrder ? 'Hide Pre Order' : 'Show Pre Order'}</button>
                  </th>
              </tr> : null}
            {booking.options.data.length > 0 ?
              <tr>
                  <td colSpan="4">
                      <div style={{ maxHeight: '400px', overflowX: 'scroll' }}>
                          <table className="table table-bordered table-striped">
                              <thead>
                              <tr>
                                  <th>Qty</th>
                                  <th>Name</th>
                                  <th>Price</th>
                                  <th>Total</th>
                              </tr>
                              </thead>
                              <tbody>
                              {booking.options.data.map(function (opt) {
                                  let quantity = opt.head_count > 0 ? opt.head_count : booking.people
                                  return <Fragment key={opt.id}>
                                      <tr>
                                          <td>{quantity}</td>
                                          <td>{opt.title}</td>
                                          <td>
                                              {Api.currencyFormatter().format(opt.price)}
                                              {opt.premium > 0
                                                ?
                                                <strong> (+{Api.currencyFormatter().format(opt.premium)})</strong>
                                                : null}
                                          </td>
                                          <td>{Api.currencyFormatter().
                                            format((opt.price * quantity) + opt.premium)}</td>
                                      </tr>
                                      {opt.pre_order_flat.length > 0 && showPreOrder ?
                                        <tr>
                                            <td colSpan={4}>
                                                {opt.price_per_booking ?
                                                  <table className="table table-bordered table-striped">
                                                      <thead>
                                                      <tr>
                                                          {opt.pre_order_flat[0].choices.map((c) => {
                                                              return <th key={c.name}>{c.name}</th>
                                                          })}
                                                      </tr>
                                                      </thead>
                                                      <tbody>
                                                      {opt.pre_order_flat.map((o) => {
                                                          return <tr>
                                                              {o.choices.map((c) => {
                                                                  return <td
                                                                    key={c.option}>{c.option} {c.premium > 0 ?
                                                                    <strong>+({Api.currencyFormatter().
                                                                      format(c.premium)})</strong> : null}</td>
                                                              })}
                                                          </tr>
                                                      })}
                                                      </tbody>
                                                  </table> :
                                                  <table className="table table-bordered table-striped">
                                                      <thead>
                                                      <tr>
                                                          <th>Name</th>
                                                          {opt.pre_order_flat[0].choices.map((c) => {
                                                              return <th key={c.name}>{c.name}</th>
                                                          })}
                                                      </tr>
                                                      {opt.pre_order_flat.map((o) => {
                                                          return <tr>
                                                              <td>{o.name}</td>
                                                              {o.choices.map((c) => {
                                                                  return <td
                                                                    key={c.option}>{c.option} {c.premium > 0 ?
                                                                    <strong>+({Api.currencyFormatter().
                                                                      format(c.premium)})</strong> : null}</td>
                                                              })}
                                                          </tr>
                                                      })}
                                                      </thead>
                                                  </table>}

                                            </td>
                                        </tr> : null}
                                  </Fragment>
                              })}
                              </tbody>
                          </table>
                      </div>
                  </td>
              </tr> : null}
            {booking.options.data.length > 0 ?
              <tr>
                  <th colSpan="4">Bespoke Options</th>
              </tr> : null}
            {booking.options.data.length > 0 ?
              <tr>
                  <td colSpan="4">
                      <table className="table table-bordered table-striped">
                          <thead>
                          <tr>
                              <th>Name</th>
                              <th>Price</th>
                          </tr>
                          </thead>
                          <tbody>
                          {booking.bespoke.data.map(function (bespoke) {
                              return <tr>
                                  <td>{bespoke.title}</td>
                                  <td>{Api.currencyFormatter().format(bespoke.price)}</td>
                              </tr>
                          })}
                          </tbody>
                      </table>
                  </td>
              </tr> : null}
            </tbody>
        </table>

        {/* <pre style={{maxHeight: '300px', overflow: 'scroll'}}>
           {JSON.stringify(booking, null, 2)}
        </pre> */}
    </div>
}

export default BookingDetails