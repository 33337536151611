import * as dayjs from "dayjs";

const SlotGenerator = (date, start_time, slot_length = 15) => {
    let slots = [];
    let time = dayjs(date + ' ' + start_time);
    let end = dayjs(date + ' ' + start_time).add(1, 'day');
    while (time.isBefore(end)) {
        slots.push(time.format('YYYY-MM-DD HH:mm'));
        time = time.add(slot_length, 'minute');
    }
    return slots;
}

const SlotGeneratorUnix = (date, start_time, slot_length = 15) => {
    let slots = [];
    let time = dayjs(date + ' ' + start_time);
    let end = dayjs(date + ' ' + start_time).add(1, 'day');
    while (time.isBefore(end)) {
        slots.push(time.unix());
        time = time.add(slot_length, 'minute');
    }
    return slots;
}
export {SlotGeneratorUnix};
export default SlotGenerator;