import {useCallback, useContext, useEffect, useRef} from 'react';
import {AppState} from "./Context/AppContext";
import dayjs from "dayjs";
import Header from "./Header/Header";
import Layout from "./Layout/Layout";
import Api from "./Api/Api";
import RightColumn from "./RightColumn/RightColumn";
import {getBusinessDay} from "./Utility/ResState";
import Modal, {ModalHelper} from "./Utility/Modal";
import EventDispatcher from "./Utility/EventDispatcher";
import Reservation from "./Reservation/Reservation";
import AddWalkIn from "./WalkIn/AddWalkIn";
import './Css/Rhost.scss';
import Settings from "./Settings";

const RHost = (props) => {
    const [state, dispatch] = useContext(AppState);
    const stateRef = useRef(state);
    stateRef.current = state;
    const getCurrentTime = useCallback(() => {
        let coeff = 1000 * 60 * state.settings.time_slot_length;
        let date = new Date();  //or use any other date
        return new Date(Math.floor(date.getTime() / coeff) * coeff)
    }, [state.settings.time_slot_length]);
    const getCurrentSession = (sessions) => {
        let state = stateRef.current;
        let current = dayjs(state.selected_time);
        let currentDate = current.format('YYYY-MM-DD');
        let session = state.session_mapping.find((s) => {
            let start = dayjs(s.start_date);
            let end = dayjs(s.end_date);
            if (current.isAfter(start) && current.isBefore(end)) {
                return true;
            }
            return false;
        });
        if (!session) {
            let session = state.session_mapping.filter((s) => {
                let start = dayjs(s.start_date);
                if (start.isAfter(currentDate)) {
                    return true;
                }
                return false;
            }).sort((s1, s2) => {
                let s1start = dayjs(s1.start_date);
                let s2start = dayjs(s2.start_date);
                return s1start.isBefore(s2start) ? -1 : 1;
            });
            return session[0];
        }
        return session;
    }
    const reloadReservations = useCallback((reservationDate) => {
        Api.get('rest/res/reservations-list', {date: reservationDate}, function (data) {
            dispatch({
                type: 'SET_RESERVATIONS',
                payload: data.data
            });
            dispatch({
                type: 'SET_LAST_RESERVATION_UPDATE',
                payload: dayjs()
            });
            dispatch({
                type: 'SET_RESERVATION_DATE',
                payload: reservationDate
            });
            dispatch({
                type: 'SET_IGNORED_WARNINGS',
                payload: data.meta.ignored_warnings
            });
        })
    }, [dispatch]);
    const getReservationUpdate = useCallback(() => {
        let state = stateRef.current;
        let diff = 999999;
        if (state.last_reservation_update !== null) {
            diff = dayjs().diff(dayjs(state.last_reservation_update), 'seconds')
        }
        let reservationDate = dayjs(state.start).format('YYYY-MM-DD');
        if (diff > state.settings.update_reservations_after || reservationDate !== state.reservation_date) {
            reloadReservations(reservationDate);
        }
    }, [dispatch, reloadReservations]);
    useEffect(() => {
        const listener = EventDispatcher.subscribe('reload-reservations', () => {
            reloadReservations(dayjs(stateRef.current.start).format('YYYY-MM-DD'));
        });
        return () => {
            EventDispatcher.unsubscribe(listener);
        }
    }, []);
    const performTick = useCallback(() => {
        let state = stateRef.current;
        if (state.time_travel === false) {
            let newDate = dayjs(getCurrentTime());
            let current = dayjs(state.selected_time);
            if (newDate.format('YYYY-MM-DD HH:mm') !== current.format('YYYY-MM-DD HH:mm')) {
                dispatch({
                    type: 'SET_CURRENT_TIME',
                    payload: getCurrentTime()
                });
            }
        }
        let bus_day = getBusinessDay(new Date());
        if (bus_day.format('YYYY-MM-DD') !== dayjs(state.business_date).format('YYYY-MM-DD')) {
            dispatch({
                type: 'SET_BUSINESS_DATE',
                payload: bus_day
            });
        }
        let start = window.performance.now();
        let session = getCurrentSession(state.session_mapping);
        if (session) {
            if (!state.active_session || session.session_id !== state.active_session.id) {
                let session_data = state.sessions.find((s) => {
                    return s.id === session.session_id;
                });
                if (!state.active_layout || session.layout_id !== state.active_layout.id) {
                    dispatch({
                        type: 'SET_ACTIVE_SESSION_AND_LAYOUT',
                        payload: {
                            session: session_data,
                            layout: state.layouts.find((l) => {
                                return l.id === session.layout_id;
                            })
                        }
                    })
                } else {
                    dispatch({
                        type: 'SET_ACTIVE_SESSION',
                        payload: session_data
                    });
                }
            }
        }
        getReservationUpdate();
        let end = window.performance.now();
        if (Settings.debug) {
            console.log('tick time', end - start);
        }
        dispatch({
            type: 'SET_LAST_TICK',
            payload: dayjs()
        })
    }, [dispatch, getCurrentTime, getReservationUpdate]);


    useEffect(() => {
        let interval = setInterval(() => performTick(), state.settings.tick_interval);
        const open_res_listener = EventDispatcher.subscribe('open-reservation', (res) => {
            ModalHelper.openModal('Reservation', <Reservation reservation={res}/>);
        })
        const add_walk_listener = EventDispatcher.subscribe('add-walk-in', (walk_in) => {
            ModalHelper.openModal('Reservation', <AddWalkIn walk_in={walk_in}/>);
        })
        return () => {
            clearInterval(interval);
            EventDispatcher.unsubscribe(open_res_listener);
            EventDispatcher.unsubscribe(add_walk_listener);
        }
    }, [state.settings.tick_interval, performTick]);
    useEffect(() => {
        performTick();
    }, [state.current_time, state.selected_time, performTick]);
    return <>
        <Modal/>
        <Header/>
        <div className="main-content">
            <Layout/>
            <RightColumn/>
        </div>
    </>
}

export default RHost;