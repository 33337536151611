import React, {useContext, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {AppState} from "../Context/AppContext";
import ListViewLevel from "./ListViewLevel";
import Loading from "../Utility/Loading/Loading";
import {ResState} from "../Utility/ResState";
import * as dayjs from "dayjs";
import ListViewReservation from "./ListViewReservation";
import _ from 'underscore';
import Settings from "../Settings";

const ListView = (props) => {
    const [state] = useContext(AppState);
    const [fullHeight, setHeight] = useState(0);
    const [levels, setLevels] = useState([]);
    const [positions, setPositions] = useState([]);
    const [tableIds, setTableIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [width, setWidth] = useState(null);
    const [indicatorLeft, setIndicatorLeft] = useState(0);
    const [times, setTimes] = useState([]);
    const [show, setShow] = useState({
        online: true,
        walk_in: true,
        other: false
    })

    let [zoomLevel, setZoomLevel] = useState(3);
    useEffect(() => {
        let name_widths = [25, 30, 35, 40, 45, 50, 55];
        let time_widths = [35, 40, 45, 50, 55, 60, 65];

        setWidth((time_widths[zoomLevel] * state.day_slots.length) + (name_widths[zoomLevel] * Math.ceil(state.day_slots.length / 8)));
    }, [zoomLevel, state.day_slots]);
    const updateTableIds = useMemo(() => {
        return state.tables.map((t) => {
            return {id: t.id};
        })
    }, [state.tables]);
    useEffect(() => {
        setTableIds(updateTableIds);
    }, [updateTableIds]);

    useEffect(() => {
        setLevels(state.levels.map((l) => {
            l.tables = state.active_layout.detail.data.filter((d) => {
                return d.level_id === l.id;
            }).map((d) => {
                d.table = ResState.getTableById(d.table_id);
                return d;
            }).filter((t) => {
                return t.table && t.table.dummy === false;
            }).sort((t1, t2) => {
                return parseInt(t1.table.number) > parseInt(t2.table.number) ? 1 : -1;
            });
            return l;
        }));
        setLoading(false);
    }, [state.levels, state.active_layout]);

    useLayoutEffect(() => {
        var start = window.performance.now();
        const checkPositions = () => {
            if (!tableIds.length) {
                return false;
            }
            var positions = {};
            var times = {};
            let totalHeight = 0;
            tableIds.map((t) => {
                let rowHeight = 0;
                state.day_slots.map((s) => {
                    let slot = s.split(' ')[1].replace(':', '');
                    let time = t.id + '-' + slot;
                    let el = document.getElementById('list_view_' + t.id + '_slot_' + slot);
                    if (el) {
                        let parent = el.parentElement;
                        if (parent && parent.clientHeight > rowHeight) {
                            rowHeight = parent.clientHeight;
                        }
                        positions[time] = {pos: {left: el.offsetLeft, top: el.offsetTop}, height: el.clientHeight};
                        times[slot] = el.offsetLeft;
                    }
                    return s;
                });
                totalHeight = totalHeight + rowHeight;
                return t;
            });
            setPositions(positions);
            setHeight(totalHeight + (state.levels.length * 35));
            setTimes(times);
            return Object.keys(positions).length > 2;
        };
        if (!checkPositions()) {
            setTimeout(() => checkPositions(), 2000);
        }
        if (Settings.debug) {
            console.log('position pre calc ', window.performance.now() - start);
        }
    }, [levels, state.levels.length, zoomLevel, state.day_slots, tableIds])
    useEffect(() => {
        let time_slot = dayjs(state.selected_time).format('HHmm');
        if (typeof times[time_slot] !== 'undefined') {
            setIndicatorLeft(times[time_slot]);
        }
    }, [zoomLevel, state.selected_time, times]);
    useEffect(() => {
        let el = document.getElementById('list_view_container');
        if (el && indicatorLeft) {
            el.scrollLeft = indicatorLeft - 100;
        }
    }, [indicatorLeft])
    let reservations = state.reservations.filter((r) => {
        return r.status_id !== Settings.status_no_show;
    });
    if (loading) {
        return <Loading/>
    }
    return <div className={"list-view clearfix ipad-scroll zoom-level-" + zoomLevel}>
        <div className="row">
            <div className="col-md-6 pull-right">
                <div className="btn-group pull-right" style={{margin: '5px 0'}}>
                    <button className="btn btn-default" disabled={zoomLevel <= 0}
                            onClick={() => setZoomLevel(zoomLevel - 1)}><i
                        className="fa fa-search-minus"/></button>
                    <button className="btn btn-default" disabled={zoomLevel >= 5}
                            onClick={() => setZoomLevel(zoomLevel + 1)}><i
                        className="fa fa-search-plus"/></button>
                </div>
            </div>
        </div>
        <div style={{
            width: '100%',
            float: 'left',
            overflow: 'scroll',
            height: 637 * state.screen.ratio,
            position: 'relative'
        }} id="list_view_container" className="ipad-scroll">
            <div className="time-indicator" style={{
                position: 'absolute',
                left: indicatorLeft + 'px',
                height: fullHeight + 'px'
            }}/>
            {reservations.map((res) => {
                let reservation_index = 0;
                let tables = res.table_ids;
                if (res.level_ids.length > 0) {
                    res.level_ids.map((level_id) => {
                        let level = ResState.getLevelById(level_id);
                        if (level) {
                            level.tables.map((t) => {
                                if (tables.indexOf(t.table_id) === -1) {
                                    tables.push(t.table_id);
                                }
                                return t;
                            })
                        }
                        return level_id;
                    })
                }
                tables = _.unique(tables);
                return tables.map(function (table) {
                    reservation_index = reservation_index + 1;
                    return <ListViewReservation table={table} reservation={res} key={res.id + '_' + table}
                                                zindex={reservation_index} positions={positions}/>
                })
            })}
            {levels.map((level) => {
                return <div className="list-view-level" style={{width: width + 'px'}} key={level.id}>
                    <ListViewLevel level={level}/>
                </div>
            })}
        </div>
    </div>
}

export default ListView;