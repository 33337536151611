import React, {useCallback, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {AppState} from "../../Context/AppContext";
import $ from "jquery";
import * as dayjs from "dayjs";
import _ from "underscore";
import "./TimeDrag.scss";

const TimeDrag = (props) => {
    const [value, setStateValue] = useState(0);
    const [left, setLeft] = useState(1);
    const [state, setState] = useContext(AppState);
    const [id] = useState(_.uniqueId());
    const [values, setValues] = useState([]);
    const calcLeft = useCallback(
        (targetValue) => {
            setLeft(targetValue);
        },
        [id, values]
    );
    const setValue = (value) => {
        setStateValue(value);
    };
    const updateValue = (e) => {
        if (values[e.target.value]) {
            let date = dayjs(
                dayjs(state.start).format("YYYY-MM-DD") + " " + values[e.target.value]
            );
            if (parseInt(date.format("HH")) < 7) {
                date = date.add(1, "day");
            }
            setState({
                type: "SET_TIME_TRAVEL_DATE",
                payload: date,
            });
        }
    };
    useEffect(() => {
        let times = [];
        let start = dayjs(
            dayjs(state.selected_time).format("YYYY-MM-DD") + " 07:00:00"
        );
        let end = dayjs(
            dayjs(state.selected_time).format("YYYY-MM-DD") + " 07:00:00"
        )
            .add(1, "day")
            .add(-1, "second");
        while (start.isBefore(end)) {
            times.push(start.format("HH:mm"));
            start = start.add(state.settings.time_slot_length, "minute");
        }
        setValues(times);
        let selectedTime = times.indexOf(
            dayjs(state.selected_time).format("HH:mm")
        );
        if (selectedTime > 0) {
            setValue(selectedTime);
        }
    }, [state.settings.time_slot_length, state.selected_time]);

    useEffect(() => {
        calcLeft(value);
    }, [value, calcLeft]);
    return (
        <div className="time-drag">
            <div className="time-range-bar">
                <input
                    type="range"
                    min={0}
                    max={values.length - 1}
                    step={1}
                    value={value}
                    name={props.name}
                    id={id}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    onTouchEnd={(e) => updateValue(e)}
                    onMouseUp={(e) => updateValue(e)}
                    className={props.className}
                />
                <output htmlFor={id} id="range_value" style={{left: left + "%"}}>
                    {values[value]}
                </output>
            </div>

            <div className="cancel-time-travel">
                <button
                    className={`btn time-reset ${!state.time_travel && 'disabled' }`}
                    onClick={state.time_travel ? (() => {
                        let value = dayjs(state.current_time);
                        setState({
                            type: "SET_TIME_TRAVEL_DATE",
                            payload: value,
                        });
                        if (props.onChange) {
                            props.onChange(value);
                        }
                    }) : null}
                >
                    < i className={`fas fa-circle ${state.time_travel && 'active' }`}/>
                </button>
            </div>


        </div>
    );
};

TimeDrag.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    onChange: PropTypes.func,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
};

export default TimeDrag;
