import React, {useContext, useEffect, useRef, useState} from 'react';
import FormElementText from "../Form/Element/Text";
import FormElementSelect from "../Form/Element/Select";
import dayjs from "dayjs";
import {AppState} from "../Context/AppContext";
import {ResState} from "../Utility/ResState";
import _ from 'underscore';
import Api from "../Api/Api";
import {ModalHelper} from "../Utility/Modal";
import './AddWalkIn.scss';
import Loading from "../Utility/Loading/Loading";
import cx from 'classnames';

const AddWalkIn = (props) => {
    const [RhostState] = useContext(AppState);
    const [state, setState] = useState({
        id: _.uniqueId('wi'),
        covers: props.walk_in.covers ? props.walk_in.covers : 2,
        start_time: props.walk_in.start_time ? props.walk_in.start_time : '',
        start_time_ts: props.walk_in.start_time ? dayjs(props.walk_in.start_time).unix() : '',
        name: '',
        table_ids: props.walk_in.tables ? props.walk_in.tables : [],
        level_ids: [],
        area_ids: [],
        end_time: props.walk_in.start_time ? dayjs(props.walk_in.start_time).add(2, 'hour').format('YYYY-MM-DD HH:mm') : '',
        end_time_ts: props.walk_in.start_time ? dayjs(props.walk_in.start_time).add(2, 'hour').unix() : '',
        mobile: '',
        length: 120,
        slots: [],
        available_covers: 0,
        status: props.walk_in.status_id ? props.walk_in.status_id : '10',
        warnings: []
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const stateRef = useRef(state);
    stateRef.current = state;
    const handleSave = () => {
        setLoading(true);
        setError(null);
        Api.postJson('rest/res/walk-in', {
            start_time: stateRef.current.start_time,
            end_time: stateRef.current.end_time,
            covers: stateRef.current.covers,
            status_id: stateRef.current.status,
            tables: stateRef.current.table_ids,
            mobile: stateRef.current.mobile,
            name: stateRef.current.name
        }, (response) => {
            setLoading(false);
            ModalHelper.closeModal();
        }, (xhr, status, err, data, errorMessage) => {
            setError(errorMessage);
            setLoading(false);
        });

    }
    const RhostStateRef = useRef(RhostState);
    RhostStateRef.current = RhostState;
    useEffect(() => {
        setTimeout(() => {
            ModalHelper.addSaveButton(() => handleSave(), 'Save');
        }, 200);
    }, []);
    useEffect(() => {
        let covers = 0;
        stateRef.current.table_ids.forEach((t) => {
            let table = ResState.getTableById(t);
            if (table) {
                covers = covers + table.seats;
            }
        });
        setState({...stateRef.current, available_covers: covers});
    }, [state.table_ids]);
    useEffect(() => {
        if (stateRef.current.start_time) {
            let warnings = ResState.getWarningsForReservation(stateRef.current, RhostStateRef.current);
            setState({...stateRef.current, warnings: warnings});
        }
    }, [state.covers, state.slots, state.start_time, state.table_ids]);

    useEffect(() => {
        let start_time = dayjs(stateRef.current.start_time);
        let start_time_ts = 0;
        let end_time_ts = 0;
        if (start_time) {
            start_time_ts = start_time.unix();
        }
        let end_time = dayjs(stateRef.current.start_time).add(stateRef.current.length, 'minute');
        if (end_time) {
            end_time_ts = end_time.unix();
        }
        let slots = ResState.createSlotArray(stateRef.current.start_time, end_time.format('YYYY-MM-DD HH:mm'));
        setState({
            ...stateRef.current,
            start_time_ts: start_time_ts,
            end_time_ts: end_time_ts,
            end_time: end_time.format('YYYY-MM-DD HH:mm'),
            slots: slots
        });
    }, [state.start_time, state.length]);
    if (loading) {
        return <Loading/>
    }
    return <div className="add-walk-in">
        {error ? <div className="alert alert-danger">{error}</div> : null}
        {state.warnings.length > 0 ? <div className="alert alert-danger">
            {state.warnings.map((w) => {
                return (
                    <div key={w.key}>
                        <span>{w.warning}.</span>
                    </div>);
            })}
        </div> : null}
        <div className="row">
            <div className="col-md-12">
                <div className="input-group covers">
                    <div className="covers-group">
                        <div className="btn-group">
                            <label htmlFor="cover-numbers">Covers</label>
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((c) => {
                                return <button data-covers="c" key={c} onClick={(e) => {
                                    setState({...state, covers: c})
                                }}
                                               className={cx('btn', {active: state.covers == c})}
                                               name="cover-numbers">{c}</button>
                            })}
                        </div>

                        {state.covers >= 8 && <div className="custom-covers">
                            <label htmlFor="covers">Custom Covers</label>
                            <FormElementText
                                number={true}
                                name="covers"
                                value={state.covers}
                                onChange={(e) => {
                                    setState({...state, covers: parseInt(e.target.value)})
                                }
                                }
                            />
                        </div>}
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <FormElementSelect name="start_time"
                                   selectedValue={state.start_time}
                                   values={ResState.getFutureTimeSlots().map(function (t) {
                                       let time = dayjs(t);
                                       return {
                                           key: t,
                                           value: time.format('HH:mm')
                                       }
                                   })} onChange={(e) => {
                    setState({...state, start_time: e.target.value})
                }} prepend="Start"/>
            </div>

            <div className="col-md-6 length-holder">
                <FormElementSelect name="length" selectedValue={state.length}
                                   values={[
                                       {key: 15, value: '15 min'},
                                       {key: 30, value: '30 min'},
                                       {key: 45, value: '45 min'},
                                       {key: 60, value: '1 hour'},
                                       {key: 75, value: '1 hour 15 min'},
                                       {key: 90, value: '1 hour 30 min'},
                                       {key: 105, value: '1 hour 45 min'},
                                       {key: 120, value: '2 hour'},
                                       {key: 135, value: '2 hour 15 min'},
                                       {key: 150, value: '2 hour 30 min'},
                                       {key: 165, value: '2 hour 45 min'},
                                       {key: 180, value: '3 hour'},
                                       {key: 195, value: '3 hour 15 min'},
                                       {key: 210, value: '3 hour 30 min'},
                                       {key: 225, value: '3 hour 45 min'},
                                   ]}
                                   onChange={(e) => setState({...state, length: e.target.value})}
                                   prepend="Length"/>
                <span className="end">{dayjs(state.end_time).format('HH:mm')}</span>
            </div>
            <div className="col-md-6">
                <FormElementText name="name" onChange={(e) => setState({...state, name: e.target.value})}
                                 value={state.name} prepend="Name"/>
            </div>
            <div className="col-md-6">
                <FormElementText name="mobile" onChange={(e) => setState({...state, mobile: e.target.value})}
                                 value={state.mobile} prepend="Mobile"/>
            </div>
            <div className="col-md-6">
                <FormElementSelect name="status_id" onChange={(e) => {
                    setState({...state, status: e.target.value})
                }}
                                   selectedValue={state.status} prepend="Status"
                                   includeBlankOption={true} blankOptionText=" - Select -"
                                   values={RhostState.statuses.map(function (item) {
                                       return {
                                           key: item.id,
                                           value: item.name
                                       }
                                   })}/>
            </div>
            <div className="col-md-6">
                <FormElementSelect name="table_ids" selectedValue={state.table_ids}
                                   values={RhostState.tables.map(function (t) {
                                       return {
                                           key: t.id,
                                           value: t.name
                                       }
                                   })}
                                   useSelect2={true}
                                   onChange={(e) => {
                                       setState({...state, table_ids: e.target.value})
                                   }}
                                   multiple={true}
                                   prepend="Tables"
                />
            </div>
        </div>
    </div>
}
export default AddWalkIn;