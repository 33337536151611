import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import EventDispatcher from "../Utility/EventDispatcher";
import {AppState} from "../Context/AppContext";
import Pusher from "pusher-js";
import Api from "../Api/Api";
import Settings from "../Settings";
import PusherBatchAuthorizer from 'pusher-js-auth';

const PusherContainer = (props) => {
    const [pusher, setPusher] = useState(null);
    const [state, dispatch] = useContext(AppState);
    const stateRef = useRef(state);
    stateRef.current = state;
    const pusherAuth = useCallback((key) => {
        const pusherInit = new Pusher(key, {
            cluster: 'eu',
            authEndpoint: Settings.endpoint + 'rest/rhost-ws-auth',
            wsHost: 'ws.revbars.cloud',
            enabledTransports: ['ws', 'wss'],
            authorizer: PusherBatchAuthorizer,
            authDelay: 100,
            auth: {
                headers: {
                    'X-Device-Token': state.settings.token,
                    'X-Device-Id': state.settings.device_id,
                    'X-Release-Timestamp': `${process.env.REACT_APP_RELEASE}`
                }
            }
        });
        setPusher(pusherInit);
    }, [setPusher, state.settings])
    useEffect(() => {
        if (pusher) {
            pusher.subscribe('presence-rhost');
            pusher.subscribe('rhost-device-' + stateRef.current.settings.device_id);
            if (stateRef.current.bar.bar_id) {
                pusher.subscribe('private-update_' + stateRef.current.bar.bar_id)
                pusher.subscribe('presence-rhost_' + stateRef.current.bar.bar_id);
            }
            const processPusherMessage = (type, data) => {
                if (type === 'reservation-new') {
                    if (data.start_time_ts >= stateRef.current.start.unix() && data.start_time_ts <= stateRef.current.end.unix()) {
                        dispatch({
                            type: 'UPDATE_RESERVATION',
                            payload: data
                        });
                        EventDispatcher.dispatch('reservation-updated', data);
                    }
                }
                if (type === 'ignored-warnings') {
                    if (data.date === state.start.format('YYYY-MM-DD')) {
                        dispatch({
                            type: 'SET_IGNORED_WARNINGS',
                            payload: data.warnings
                        });
                    }
                }
                if (type === 'device_authorised') {
                    if (data.device_id === stateRef.current.settings.device_id) {
                        document.location.reload();
                    }
                }

                if (type === 'unread_update') {
                    if (parseInt(data.device_id) === parseInt(stateRef.current.settings.device_id)) {
                        dispatch({
                            type: 'SET_UNREAD_CHAT',
                            payload: data.count
                        });
                    }
                }

                if (type === 'chat') {
                    EventDispatcher.dispatch('chat', data);
                    if (data.res_device_id !== Api.device_id) {
                        EventDispatcher.dispatch('show-info', <>
                            <i className="fab fa-facebook-messenger"/>
                            <strong>{data.device_name ? data.device_name : data.user_name}</strong> {data.message}
                        </>);
                    }
                }

                if (type === 'checkin') {
                    if (parseInt(data.device_id) === parseInt(stateRef.current.settings.device_id)) {
                        // Api.postJson('rest/res/device-check-in/' + data.id, {
                        //     state: ResState,
                        //     settings: Settings
                        // }, function (data) {
                        //     console.log(data);
                        // });
                    }
                }
                if (type === 'reload') {
                    if (parseInt(data.device_id) === parseInt(stateRef.current.settings.device_id)) {
                        document.location.reload(true);
                    }
                }

                if (type === 'track_trace') {
                    //EventDispatcher.dispatch('track_trace', data.data);
                }

                if (type === 'message') {
                    if (data.device_id) {
                        alert('Message From I.T.:' + data.message);
                    } else {
                        if (data.device_id === stateRef.current.settings.device_id) {
                            alert('Message From I.T.:' + data.message);
                        }
                    }
                }
            }
            pusher.bind_global((type, data) => processPusherMessage(type, data));
            pusher.connection.bind('state_change', () => {
                if (pusher.connection.state === 'connected') {
                    dispatch({
                        type: 'SET_OFFLINE',
                        payload: false
                    })
                } else {
                    dispatch({
                        type: 'SET_OFFLINE',
                        payload: true
                    })
                }
            })
        }
    }, [pusher, stateRef.current.settings.device_id, stateRef.current.bar, dispatch]);
    useEffect(() => {
        Api.get('rest/res/ws/token', {}, (data) => {
            pusherAuth(data.key);
        });
    }, [pusherAuth]);
    return <span/>
}

export default PusherContainer;