import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import dayjs from "dayjs";
import Settings from "../Settings";
import FormElementText from "../Form/Element/Text";
import {ResState} from "../Utility/ResState";
import FormElementSelect from "../Form/Element/Select";
import {AppState} from "../Context/AppContext";
import {ModalHelper} from "../Utility/Modal";
import Api from "../Api/Api";
import EventDispatcher from "../Utility/EventDispatcher";

const CreateBookingFromReservation = (props) => {
    const [reservation, setReservation] = useState({
        booking_id: props.booking.booking_id,
        tables: [],
        start_time: dayjs(props.booking.booking_date).format('YYYY-MM-DD HH:mm'),
        end_time: dayjs(props.booking.booking_date).add(2, 'hour').format('YYYY-MM-DD HH:mm'),
        covers: props.booking.no_people,
        status_id: Settings.status_upcoming
    });
    const [error, setError] = useState(null);
    const handleSave = () => {
        setError(null);
        ModalHelper.disableSaveButton();
        Api.postJson('rest/res/from-booking', reservation, (response) => {
            ModalHelper.closeModal();
            EventDispatcher.dispatch('reload-reservations');
        }, (xhr, status, err, data, responseData) => {
            ModalHelper.enableSaveButton();
            setError(responseData);
        });
    }
    useEffect(() => {
        setTimeout(() => {
            ModalHelper.addSaveButton(handleSave, 'Save');
            ModalHelper.disableSaveButton();
        }, 200);
    }, []);
    useEffect(() => {
        let valid = true;
        if (!reservation.start_time) {
            valid = false;
        }
        if (!reservation.end_time) {
            valid = false;
        }

        if (!reservation.covers) {
            valid = false;
        }
        if (reservation.tables.length === 0) {
            valid = false;
        }
        if (valid) {
            ModalHelper.enableSaveButton();
        } else {
            ModalHelper.disableSaveButton();
        }
    }, [reservation]);
    const [state] = useContext(AppState);
    return <div className="create-booking-from-reservation">
        {error && <div className="alert alert-danger">{error}</div>}
        This will create a reservation for booking {props.booking.party_name} ({props.booking.ref_code}).

        <FormElementSelect name="start_time" selectedValue={reservation.start_time}
                           prepend="Start Time"
                           onChange={(e) => setReservation({...reservation, start_time: e.target.value})}
                           values={ResState.state.day_slots.map((s) => {
                               return {
                                   key: s,
                                   value: dayjs(s).format('HH:mm')
                               }
                           })}
        />
        <FormElementSelect name="end_time" selectedValue={reservation.end_time}
                           prepend="End Time"
                           onChange={(e) => setReservation({...reservation, end_time: e.target.value})}
                           values={ResState.state.day_slots.map((s) => {
                               return {
                                   key: s,
                                   value: dayjs(s).format('HH:mm')
                               }
                           })}
        />
        <FormElementText name="covers" prepend="Covers"
                         number={true}
                         onChange={(e) => setReservation({...reservation, covers: parseInt(e.target.value)})}
                         value={reservation.covers}/>
        <FormElementSelect
            name="table_ids"
            selectedValue={reservation.tables}
            values={state.tables.map(function (t) {
                return {
                    key: t.id,
                    value: t.name
                }
            })}
            disabled={false}
            useSelect2={true}
            onChange={(e) => {
                setReservation({...reservation, tables: e.target.value})
            }}
            multiple={true}
            prepend="Tables"
        />
    </div>
}

CreateBookingFromReservation.propTypes = {
    booking: PropTypes.object.isRequired
}

export default CreateBookingFromReservation;