import AppWrapper from "./Context/AppContext";
import RHost from "./Rhost";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import parse from 'dayjs/plugin/customParseFormat'
import sameOrBefore from 'dayjs/plugin/isSameOrBefore';
import sameOrAfter from 'dayjs/plugin/isSameOrAfter';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import './Css/Vendor/bootstrap/css/bootstrap.min.css';
import './Css/Vendor/bootstrap/css/bootstrap-theme.min.css';
import './Css/Vendor/fontawesome/css/fontawesome-all.min.css';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/draggable';

dayjs.extend(duration);
dayjs.extend(parse);
dayjs.extend(relativeTime)
dayjs.extend(sameOrBefore);
dayjs.extend(sameOrAfter);
dayjs.extend(advancedFormat);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: 'a few seconds',
        m: "a minute",
        mm: "%d min",
        h: "an hour",
        hh: "%d h",
        d: "a day",
        dd: "%d d",
        M: "a month",
        MM: "%d month",
        y: "a year",
        yy: "%d y"
    }
})

function App() {
    return (
        <AppWrapper>
            <div className="App">
                <RHost/>
            </div>
        </AppWrapper>
    );
}

export default App;
