import React from 'react';
import ListViewLevelTable from "./ListViewLevelTable";

const ListViewLevel = React.memo((props) => {
    let level = props.level;
    return <>
        <div className="level-header">{level.name}</div>
        <div className="level-tables">
            {props.level.tables.map((t) => {
                return <div className="level-table-row clearfix" key={t.id}>
                    {t.table.slots.map((slot) => {
                        return <ListViewLevelTable table={t} level={level} slot={slot} key={slot.id}/>
                    })}
                </div>
            })}
        </div>
    </>
});

export default ListViewLevel;