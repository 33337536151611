import React from 'react';
import dayjs from "dayjs";
import PropTypes from 'prop-types';
import './ReservationRow.scss';
import {ModalHelper} from "../Utility/Modal";
import CreateBookingFromReservation from "../Reservation/CreateBookingFromReservation";

const UnassignedBookingRow = (props) => {
    let booking = props.booking;
    let startTime = dayjs(booking.booking_date);

    return <li className="available-slot right-col-reservation-row">
        <button className="btn res-info" onClick={(e) => {
            ModalHelper.openModal('Create Reservation', <CreateBookingFromReservation booking={booking}/>);
        }}>
            <div className="res-group">
                <span className="covers">{booking.no_people} <i className="far fa-user"/></span>
                <span className="time">{startTime.format('HH:mm')}</span>
                <span style={startTime < dayjs() ? {fontWeight: 'bold', color: 'red'} : null} className="time-diff">
                        <i className="far fa-clock"/>
                </span>
                <span className={"tables status-" + (booking.status)}>
                    {booking.status_name}
                </span>
            </div>

            <div className="res-content">
                <span className="name">{booking.party_name}</span>
            </div>
        </button>
    </li>
}

UnassignedBookingRow.propTypes = {
    booking: PropTypes.object.isRequired
}
export default UnassignedBookingRow;