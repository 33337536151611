import React, {useEffect, useRef, useState} from 'react';
import EventDispatcher from "./EventDispatcher";
import $ from 'jquery';

const Modal = (props) => {
    const defaultState = {
        show: false,
        wrapper_class_name: '',
        modal_title: '',
        hide_close: false,
        content: <span/>,
        save_button: false,
        save_button_disabled: false,
        save_text: 'Save',
        close_text: 'Close',
        save_action: () => {
        }
    };
    const [state, setState] = useState(defaultState);
    const stateRef = useRef(state);
    stateRef.current = state;
    useEffect(() => {
        const open_listener = EventDispatcher.subscribe('open-modal', (options) => {
            setState({...stateRef.current, content: options.content, modal_title: options.modal_title, show: true});
        });
        const close_listener = EventDispatcher.subscribe('close-modal', () => {
            setState({
                show: false,
                wrapper_class_name: '',
                modal_title: '',
                hide_close: false,
                content: <span/>,
                save_button: false,
                save_button_disabled: false,
                save_text: 'Save',
                close_text: 'Close',
                save_action: () => {
                }
            });
        });
        const update_listener = EventDispatcher.subscribe('update-modal', (options) => {
            setState({...stateRef.current, ...options});
        })
        return () => {
            EventDispatcher.unsubscribe(open_listener);
            EventDispatcher.unsubscribe(close_listener);
            EventDispatcher.unsubscribe(update_listener);
        }
    }, []);
    if (state.show === false) {
        return <></>;
    }
    return <div className={"modal show " + state.wrapper_class_name} role="dialog" id="modal">
        <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{state.modal_title}</h5>
                    {state.hide_close ? null :
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="fas fa-times"
                                aria-hidden="true" 
                                onClick={() => {
                                    setState(defaultState);
                                }}>
                            </i>
                        </button>}
                </div>
                <div className="modal-body">
                    {state.content}
                </div>
                <div className="modal-footer">
                    {state.hide_close ? null :
                        <button type="button" className="btn btn-default" onClick={() => {
                            setState(defaultState);
                        }}
                                data-dismiss="modal">{state.close_text}</button>}
                    {state.save_button ?
                        <button className="btn btn-success" onClick={state.save_action}
                                disabled={state.save_button_disabled}>{state.save_text}</button> : null}
                </div>
            </div>
        </div>
    </div>
}


const ModalHelper = {
    openModal: function (title, content) {
        EventDispatcher.dispatch('open-modal', {
            content: content,
            modal_title: title
        })
    },
    closeModal: function () {
        EventDispatcher.dispatch('close-modal')
    },
    updateModal: function (options) {
        if ($('.modal').length === 0) {
            setTimeout(() => this.updateModal(options), 100);
        }
        EventDispatcher.dispatch('update-modal', options);
    },
    addSaveButton: function (callback, text) {
        if (!text) {
            text = 'Save';
        }
        this.updateModal({
            save_button: true,
            save_action: callback,
            save_text: text
        });
    },
    disableSaveButton: function () {
        this.updateModal({
            save_button_disabled: true
        });
    },
    enableSaveButton: function () {
        this.updateModal({
            save_button_disabled: false
        });
    }
};
export {ModalHelper};
export default Modal;