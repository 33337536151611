import React, {createContext, useCallback, useEffect, useReducer, useState} from 'react';
import Loading from "../Utility/Loading/Loading";
import Api, {LocalStorage} from "../Api/Api";
import AuthDevice from "../Authentication/AuthDevice";
import 'clientjs';
import * as dayjs from "dayjs";
import $ from 'jquery';
import _ from 'underscore';
import SlotGenerator from "../Utility/SlotGenerator";
import {defaultState, getBusinessDay, ResState} from "../Utility/ResState";
import PusherContainer from "../Pusher/PusherContainer";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EventDispatcher from "../Utility/EventDispatcher";
import Settings from "../Settings";
import * as Sentry from '@sentry/react';

const initialState = _.extend({}, defaultState);
const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DEVICE_ID':
            Sentry.setUser({
                device_id: action.payload
            });
            Api.device_id = action.payload;
            state = {
                ...state,
                settings: {...state.settings, device_id: action.payload}
            }
            break;
        case 'MOVE_RESERVATION':
            state = {
                ...state,
                move_reservation: action.payload
            };
            break;
        case 'SET_OFFLINE':
            state = {...state, offline: action.payload}
            break;
        case 'SET_SCREEN_SIZE':
            state = {
                ...state,
                screen: {
                    ...state.screen,
                    width: action.payload.width,
                    ratio: action.payload.ratio,
                    visible_height: action.payload.visible_height
                }
            }
            break;
        case 'SET_DAY_SLOTS':
            state = {...state, day_slots: action.payload}
            break;
        case 'SET_DEVICE':
            state = {...state, device: action.payload}
            break;
        case 'SET_LAST_RESERVATION_UPDATE':
            state = {...state, last_reservation_update: action.payload}
            break;
        case 'SET_LEVELS':
            state = {...state, levels: action.payload}
            break;
        case 'SET_AREAS':
            state = {...state, areas: action.payload}
            break;
        case 'SET_TOKEN':
            Api.token = action.payload;
            Sentry.setUser({
                device_id: Api.device_id,
                token: action.payload
            });
            state = {
                ...state,
                settings: {...state.settings, token: action.payload}
            }
            break;
        case 'SET_BUSINESS_DATE':
            state = {
                ...state,
                business_date: action.payload
            }
            break;
        case 'SET_LAYOUTS':
            state = {
                ...state,
                layouts: action.payload
            };
            break;
        case 'SET_SESSIONS':
            state = {
                ...state,
                sessions: action.payload
            };
            break;
        case 'SET_ACTIVE_LAYOUT':
            state = {
                ...state,
                active_layout: action.payload
            };
            break;
        case 'SET_IGNORED_WARNINGS':
            state = {
                ...state,
                ignored_warnings: action.payload
            };
            break;
        case 'SET_ACTIVE_SESSION':
            state = {
                ...state,
                active_session: action.payload
            };
            break;
        case 'SET_ACTIVE_SESSION_AND_LAYOUT':
            state = {
                ...state,
                active_session: action.payload.session,
                active_layout: action.payload.layout
            }
            break;
        case 'SET_DEVICE_NOT_FOUND':
            state = {
                ...state,
                device_not_found: action.payload
            }
            break;
        case 'SET_INITIAL_DATA':
            state = {
                ...state,
                sessions: action.payload.sessions,
                layouts: action.payload.layouts,
                tables: action.payload.tables,
                session_mapping: action.payload.session_mapping,
                device: action.payload.device,
                bar: action.payload.bar,
                levels: action.payload.levels,
                statuses: action.payload.statuses,
                pusher_key: action.payload.pusher_key,
                areas: action.payload.areas,
                message_templates: action.payload.message_templates,
                unread_chat: action.payload.unread_chat,
                ignored_warnings: []
            };
            break;
        case 'SET_TABLES':
            let tables = action.payload;
            let slots = SlotGenerator(getBusinessDay(state.selected_time).format('YYYY-MM-DD'), state.settings.business_date_start, state.settings.time_slot_length).map((slot) => {
                state = {
                    time: slot,
                }
                return slot;
            });
            tables = tables.map((t) => {
                t.slots = slots;
                return t;
            });
            state = {
                ...state,
                tables: tables
            };
            break;
        case 'SET_RESERVATIONS':
            state = {
                ...state,
                reservations: action.payload.reservations.data,
                bookings: action.payload.bookings.data,
            }
            break;
        case 'UPDATE_RESERVATION':
            let found = false;
            let reservations = state.reservations.map((res) => {
                if (res.id === action.payload.id) {
                    found = true;
                    return action.payload;
                }
                return res;
            });
            if (!found) {
                reservations.push(action.payload);
            }
            state = {
                ...state,
                reservations: reservations
            }
            break;
        case 'SET_TIME_TRAVEL':
            state = {
                ...state,
                time_travel: action.payload
            };
            break;
        case 'SET_UNREAD_CHAT':
            state = {
                ...state,
                unread_chat: action.payload
            }
            break;
        case 'SET_TIME_TRAVEL_DATE':
            let time_travel = true;
            if (dayjs(action.payload.toDate()).isSame(dayjs(state.current_time))) {
                time_travel = false;
            }
            const start = getBusinessDay(dayjs(action.payload.toDate()));
            const end = start.add(1, 'day').add(-1, 'second');
            let timeSlots = SlotGenerator(dayjs(action.payload).format('YYYY-MM-DD'), state.settings.business_date_start, state.settings.time_slot_length);

            state = {
                ...state,
                time_travel: time_travel,
                selected_time: action.payload,
                day_slots: timeSlots,
                start: start,
                end: end,
                tables: state.tables.map((t) => {
                    t.slots = timeSlots.map((s) => {
                        return {
                            time: s,
                            reservations: []
                        }
                    });
                    return t;
                })
            }
            break;
        case 'SET_LAST_TICK':
            state = {...state, last_tick: action.payload}
            break;
        case 'SET_RESERVATION_DATE':
            state = {...state, reservation_date: action.payload}
            break;
        case 'SET_CURRENT_TIME':
            if (state.selected_time === null) {
                state = {
                    ...state,
                    current_time: action.payload,
                    selected_time: action.payload,
                    day_slots: SlotGenerator(dayjs(action.payload).format('YYYY-MM-DD'), state.settings.business_date_start, state.settings.time_slot_length),
                    time_travel: false
                }
            }
            if (state.selected_time === state.current_time) {
                state = {
                    ...state,
                    current_time: action.payload,
                    selected_time: action.payload,
                    time_travel: false
                }
                break;
            }
            state = {
                ...state,
                current_time: action.payload,
                time_travel: true
            }
            break;
        default:
            return state;
    }
    if (['SET_INITIAL_DATA', 'SET_ACTIVE_SESSION_AND_LAYOUT', 'SET_CURRENT_TIME', 'SET_TIME_TRAVEL_DATE', 'SET_RESERVATIONS', 'UPDATE_RESERVATION', 'SET_ACTIVE_LAYOUT', 'SET_ACTIVE_SESSION', 'SET_LAST_TICK'].indexOf(action.type) > -1) {

        let stateStart = window.performance.now();
        ResState.setState(state);
        state = ResState.postStateChecks(state);
        let stateEnd = window.performance.now();
        if (Settings.debug) {
            console.log('State Check', stateEnd - stateStart);
        }
    }
    return state;
};
const AppWrapper = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    const [loading, setLoading] = useState(true);
    let [auth, setAuth] = useState(false);
    const getCurrentTime = useCallback(() => {
        let coeff = 1000 * 60 * state.settings.time_slot_length;
        let date = new Date();  //or use any other date
        return new Date(Math.floor(date.getTime() / coeff) * coeff)
    }, [state.settings.time_slot_length]);
    const getSize = useCallback(() => {
        let width = ($('.layout').width());
        let ratio = width / state.screen.base_width;
        let iOS = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        let max_height = 0;
        if (iOS) {
            max_height = window.innerHeight * 0.65;
        } else {
            max_height = window.innerHeight * 0.83;
        }
        let max_ratio = max_height / state.screen.base_height;
        if (state.screen.base_height * ratio > max_height) {
            ratio = max_ratio;
        }
        dispatch({
            type: 'SET_SCREEN_SIZE',
            payload: {
                width: width,
                ratio: ratio,
                visible_height: $(window).height()
            }
        })
    }, [state.screen.base_height, state.screen.base_width]);
    const getRhostDeviceState = useCallback(() => {
        Api.get('rest/res/rhost/site-data', {}, (data) => {
            setAuth(true);
            Settings.debug = data.data.device.data.debug;
            dispatch({
                type: 'SET_INITIAL_DATA',
                payload: {
                    sessions: data.data.sessions.data,
                    tables: data.data.tables.data.map((t) => {
                        t.status = {};
                        return t;
                    }),
                    message_templates: data.data.message_templates.data,
                    layouts: data.data.layouts.data,
                    areas: data.data.areas.data,
                    session_mapping: data.data.session_mapping.data,
                    device: data.data.device.data,
                    bar: data.data.bar.data,
                    pusher_key: data.data.pusher_key.data,
                    levels: data.data.levels.data.sort((l1, l2) => {
                        return l1.position > l2.position ? 1 : -1;
                    }),
                    unread_chat: data.data.unread_chat.data,
                    statuses: data.data.statuses.data
                }
            });
            let slots = [];
            let time = dayjs(state.settings.business_date_start, 'HH:mm');
            let end = dayjs(state.settings.business_date_start, 'HH:mm').add(1, 'day');
            while (time.isBefore(end)) {
                slots.push(time.format('HH:mm'));
                time = time.add(state.settings.time_slot_length, 'minute');
            }
            setLoading(false);
            getSize();
            window.addEventListener('resize', (e) => {
                getSize();
            });
        }, function (request) {
            if (request.status === 403) {
                setAuth(false);
            }
            if (request.status === 404) {
                dispatch({
                    type: 'SET_DEVICE_NOT_FOUND',
                    payload: true
                })
            } else {
                dispatch({
                    type: 'SET_DEVICE_NOT_FOUND',
                    payload: false
                })
            }
            setLoading(false);
            //setTimeout(() => getRhostDeviceState(), 5000);
        });
    }, [state.settings.business_date_start, state.settings.time_slot_length, getSize]);
    useEffect(() => {
        dispatch({
            type: 'SET_CURRENT_TIME',
            payload: getCurrentTime()
        });
        if (!state.settings.device_id) {
            LocalStorage.get('device_id').then((data) => {
                if (data === null) {
                    const client = new ClientJS();// eslint-disable-line no-undef
                    let id = client.getFingerprint();
                    LocalStorage.set('device_id', id);
                    dispatch({
                        type: 'SET_DEVICE_ID',
                        payload: id
                    });
                } else {
                    dispatch({
                        type: 'SET_DEVICE_ID',
                        payload: data
                    });
                }
            });
            return;
        }
        if (!state.settings.token) {
            LocalStorage.get('token').then((data) => {
                if (data === null) {
                    getRhostDeviceState();
                } else {
                    dispatch({
                        type: 'SET_TOKEN',
                        payload: data
                    });
                }
            });

            return;
        }
        getRhostDeviceState();
    }, [state.settings.device_id, state.settings.token, getRhostDeviceState, getCurrentTime, getSize]);
    useEffect(() => {
        const messageListener = EventDispatcher.subscribe('show-notification', (message) => {
            toast.success(message);
        });
        const errorListener = EventDispatcher.subscribe('show-error', (message) => {
            toast.error(message);
        });
        const infoListener = EventDispatcher.subscribe('show-info', (message) => {
            toast.info(message);
        })
        return () => {
            EventDispatcher.unsubscribe(messageListener);
            EventDispatcher.unsubscribe(errorListener);
            EventDispatcher.unsubscribe(infoListener);
        }
    }, []);
    return <AppState.Provider value={[state, dispatch]}>
        {loading ? <Loading full_page={true}/> : <>
            <ToastContainer postion="top-left" autoClose={2000} hideProgressBar={true} newestOnTop={true}/>
            <PusherContainer/>
            {!auth ? <AuthDevice/> : children}
        </>}
    </AppState.Provider>
}
export const AppState = createContext(initialState);
export default AppWrapper;