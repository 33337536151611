import React from 'react';
import dayjs from "dayjs";
import PropTypes from 'prop-types';
import EventDispatcher from "../Utility/EventDispatcher";
import './ReservationRow.scss';
import Api from "../Api/Api";

const ReservationRow = (props) => {
    let res = props.reservation;
    var options = props.reservation.options;
    let warning = null;
    if (res.has_special_requirements) {
        warning = <i className="fa fa-exclamation-triangle"/>
    }
    let offer = null;
    var covid_meeting = null;
    let name = res.display_name;
    if (res.offer_name) {
        offer = <span><i className="fa fa-ticket-alt"/> {res.offer_name}</span>
    }
    if (res.on_booking_notes) {
        name =
            <span>{res.display_name}{warning}<br/><i
                className="fa fa-info"/> {res.on_booking_notes}{offer}</span>
    } else {
        name = <span>{res.display_name}{offer}{warning}</span>
    }
    covid_meeting = res.covid_business_meeting;
    var food_time = null;
    if (res.food_time) {
        food_time = <span>Food: {dayjs(res.food_time).format('HH:mm')}</span>
    }

    let resStartTime = dayjs(res.start_time);
    let timeDiff = dayjs(resStartTime.diff(dayjs())).subtract(1, 'hour').format('HH:mm');

    let icon = res.customer_type_icon;

    let resTables = [];
    for (let [idx, table] of res.reserved_spaces.entries()) {
        resTables.push(<span key={idx} className="table">{table}</span>)
    }

    return <li className="available-slot right-col-reservation-row">
        <button className="btn res-info" onClick={(e) => {
            EventDispatcher.dispatch('open-reservation', res);

        }}>
            <div className="res-group">
                <span className="covers">{res.covers} <i className="far fa-user"/></span>
                {res.allow_standing ?
                    <span className="standing-space">
                    <i className="fa fa-male"/><i className="fas fa-check"></i>
                </span> :
                    <span className="standing-space">
                <i className="fa fa-male"/><i className="fas fa-times"></i>
            </span>
                }
                <span className="time">{dayjs(res.start_time).format('HH:mm')}</span>
                <span style={resStartTime < dayjs() ? {fontWeight: 'bold', color: 'red'} : null} className="time-diff">
                        <i className="far fa-clock"/>
                    {resStartTime < dayjs() ? 'Late' : timeDiff}
                </span>
                <span className="tables"
                      style={res.reserved_spaces.length <= 1 ?
                          {justifyContent: 'center'} :
                          {justifyContent: 'space-between'}}>
                {resTables}
                </span>
            </div>
            {res.deposit_total < res.pbr &&
            <span className="pbr">Balance Due: {Api.currencyFormatter().format(res.pbr - res.deposit_total)}</span>}

            <div className="res-content">
                <span className="name">{name}{food_time}</span>
                {res.allergy_status === 2?<span style={{color: 'red'}}><i
                    className="fa fa-exclamation-triangle"/> Check Allergy Info</span>:null}
                  {icon ?
                    <span className="customer-type">
                        <img src={icon} className="customer-type-icon"
                             alt={res.customer_type_name ? res.customer_type_name : 'Customer Type'}/>
                        {res.customer_type_name ? res.customer_type_name : ''}
                    </span> : null}
                {covid_meeting ?
                    <span className="name"><img src="/images/mask.png" alt="Covid Business Meeting"
                                                style={{width: '15px'}}/> Covid Business Meeting</span> : null}
                {options.length > 0 ? <span className="pre-order">
                        {options.map((opt) => {
                            return <span key={opt}>{opt}</span>
                        })}
                    </span> : null}
            </div>
        </button>
    </li>
}

ReservationRow.propTypes = {
    reservation: PropTypes.object.isRequired
}
export default ReservationRow;