import React, {useContext, useEffect, useRef, useState} from 'react';
import Api from "../Api/Api";
import * as dayjs from "dayjs";
import FormElementText from "../Form/Element/Text";
import EventDispatcher from "../Utility/EventDispatcher";
import _ from 'underscore';
import ChatEntry from "./ChatEntry";
import {AppState} from "../Context/AppContext";
import './Chat.scss'

const Chat = (props) => {
    const [chat, setChat] = useState([]);
    const [newChat, setNewChat] = useState('');
    const [, setState] = useContext(AppState);
    const chatRef = useRef(chat);
    chatRef.current = chat;
    useEffect(() => {
        let start = dayjs().add(-1, 'day').format('YYYY-MM-DD HH:mm:ss');
        let end = dayjs().add(10, 'minute').format('YYYY-MM-DD HH:mm:ss');
        Api.get('rest/res/chat', {start_time: start, end_time: end}, (data) => {
            setChat(data.data);
            setState({
                type: 'SET_UNREAD_CHAT',
                payload: data.meta.count
            })
        })
    }, [setState]);
    const sendChat = (e) => {
        e.preventDefault();
        Api.post('rest/res/chat', {message: newChat}, () => {
            setNewChat('');
        });
    }
    useEffect(() => {
        const listener = EventDispatcher.subscribe('chat', (data) => {
            let chat = _.extend([], chatRef.current);
            chat.push(data);
            setChat(chat);
        });
        return () => {
            EventDispatcher.unsubscribe(listener);
        }
    }, []);

    // Scroll chat to bottom on open
    useEffect(() => {
        let el = document.getElementById('chat_history');
        if (el) {
            el.scrollTop = el.scrollHeight;
        }
    }, [chat])

    return <div className="chat">
        <div className="chat-history" id="chat_history">
            {chat.map((c) => {
                return <ChatEntry chat={c} key={c.id}/>
            })}
        </div>
        <form onSubmit={(e) => sendChat(e)} className="chat-entry-form">
            <FormElementText name="chat" value={newChat} placeholder="Message. . ." onChange={(e) => setNewChat(e.target.value)}/>
            <button className="btn btn-sm">Send</button>
        </form>
    </div>
}

export default Chat;