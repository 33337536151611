const Settings = {
    endpoint: '/api/',
    device_id: null,
    token: null,
    status_no_show: 90,
    status_upcoming: 10,
    status_seated: 20,
    status_finalised: 60,
    status_finished: 70,
    time_slot_length: 15,
    debug: false,
    capabilities: {
        food_table: 1,
        walk_in: 2
    }
};

export default Settings;