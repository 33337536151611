import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import dayjs from "dayjs";
import gb from 'date-fns/locale/en-US';

registerLocale('gb', gb)
setDefaultLocale('gb');

export default function FormElementDatePicker(props) {
    let [, setValue] = useState(props.value);
    useEffect(() => {
        setValue(props.value);
    }, [props.value])
    let selectedValue = props.value ? dayjs(props.value).toDate() : '';
    let element = <DatePicker dateFormat="dd/MM/yyyy" selected={selectedValue} onChange={(date) => {
        let value = dayjs(date).format('YYYY-MM-DD');
        setValue(value);
        if (props.onChange) {
            props.onChange({target: {value: value}});
        }
    }} className="form-control"/>

    if (props.prepend || props.append) {
        return <div className="form-group">
            <label htmlFor={props.name}>{props.prepend ? props.prepend : props.append}</label>
            {element}
        </div>
    }
    return element;
}

FormElementDatePicker.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    defferredUpdate: PropTypes.bool,
    number: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    maxLength: PropTypes.number,
    error: PropTypes.bool
};