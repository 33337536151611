import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {AppState} from "../Context/AppContext";
import Loading from "../Utility/Loading/Loading";
import dayjs from "dayjs";
import FormElementText from "../Form/Element/Text";
import Api from "../Api/Api";
import FormElementSelect from "../Form/Element/Select";
import {ResState} from "../Utility/ResState";
import Settings from "../Settings";
import FormElementDatePicker from "../Form/Element/DatePicker";
import {ModalHelper} from "../Utility/Modal";
import EventDispatcher from "../Utility/EventDispatcher";

const NewBooking = (props) => {
    const [booking, setBooking] = useState({
        date: '',
        time: '',
        covers: 0,
        email: '',
        forename: '',
        surname: '',
        phone: '',
        tables: []
    });
    const [error, setError] = useState(null);

    const [availableTables, setAvailableTables] = useState([]);

    const [loading, setLoading] = useState(true);
    const [hasUpdate, setHasUpdate] = useState(false);

    const bookingRef = useRef(booking);
    bookingRef.current = booking;
    const [availability, setAvailability] = useState({times: []})

    const [state] = useContext(AppState);
    const handleSave = () => {
        Api.postJson('rest/res/create-booking', bookingRef.current, (data) => {
            if (data.data.id) {
                ModalHelper.closeModal();
                EventDispatcher.dispatch('show-notification', 'Booking has been added');
            }
        }, (xhr, status, err, data, response) => {
            EventDispatcher.dispatch('show-error', err);
            setError(err);
        })
    }
    const updateAvailability = useCallback((forceUpdate) => {
        setAvailability({times: []});
        setError(null);
        setAvailableTables([]);
        if ((hasUpdate || forceUpdate) && booking.covers && booking.date) {
            setLoading(true);
            Api.get('rest/res/availability-by-type', {
                bar_id: state.bar.id,
                covers: booking.covers,
                date: booking.date,
                capability: Settings.capabilities.food_table
            }, (response) => {
                setLoading(false);
                if (booking.time) {
                    let has_time = false;
                    response.times.map((t) => {
                        if (t.time === booking.time) {
                            has_time = true;
                        }
                        return t;
                    });
                    if (has_time === false) {
                        setBooking({...booking, time: '', tables: []});
                    }
                }
                setAvailability(response);
            });
        }
        setHasUpdate(false);
    }, [setAvailability, setLoading, setHasUpdate, booking, hasUpdate, state.bar.id]);
    useEffect(() => {
        setHasUpdate(true);
    }, [booking.date, booking.covers, setHasUpdate]);
    useEffect(() => {
        let slot_time = availability.times.find((t) => {
            return t.time === booking.time;
        });
        if (!slot_time) {
            setAvailableTables([]);
            return;
        }
        let tables = slot_time.tables.filter((t) => {
            return t.length === 1;
        }).map((t) => {
            t = t[0];
            return ResState.getTableById(t);
        }).sort((t1, t2) => {
            return parseInt(t1.number) > parseInt(t2.number) ? 1 : -1;
        });
        setAvailableTables(tables);
    }, [booking.time, availability.times]);
    useEffect(() => {
        updateAvailability(true);
    }, [booking.date]);
    useEffect(() => {
        let canSave = true;
        if (!booking.date) {
            canSave = false;
        }
        if (!booking.covers) {
            canSave = false;
        }
        if (!booking.time) {
            canSave = false;
        }
        if (!booking.forename) {
            canSave = false;
        }
        if (!booking.surname) {
            canSave = false;
        }
        if (!booking.email) {
            canSave = false;
        }
        if (!booking.phone) {
            canSave = false;
        }
        if (canSave) {
            ModalHelper.enableSaveButton();
        } else {
            ModalHelper.disableSaveButton();
        }
    }, [booking]);
    useEffect(() => {
        setBooking({
            date: dayjs(state.selected_time).format('YYYY-MM-DD'),
            time: '',
            covers: 0,
            email: '',
            forename: '',
            surname: '',
            phone: '',
            tables: []
        });
        setLoading(false);
        ModalHelper.addSaveButton(handleSave, 'Add Booking');
    }, [state.current_time]);

    if (loading) {
        return <Loading/>
    }
    return <div>
        <div className="alert alert-info">Add a new booking to PartyPro. Adding this booking will bypass the need for
            the customer to have a saved card (i.e. no show charges can't be applied)
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="row">
            <div className="col-md-6">
                <FormElementDatePicker name="date" value={booking.date}
                                       prepend="Date"
                                       onChange={
                                           (e) => {
                                               setBooking({...booking, date: e.target.value});
                                           }
                                       }
                />
            </div>
            <div className="col-md-6">
                <FormElementText number={true} name="covers" value={booking.covers}
                                 prepend="Covers"
                                 onBlur={() => updateAvailability()}
                                 onChange={(e) => setBooking({...booking, covers: parseInt(e.target.value)})}/>
            </div>
            {booking.date && booking.covers > 0 ? <>
                {availability.times.length === 0 ? <div className="col-md-12">
                    {hasUpdate ? null :
                        <div className="alert alert-danger">
                            There doesn't appear to be any food table bookings left on this
                            day for that amount of people</div>}
                </div> : <>
                    <div className="col-md-6">
                        <FormElementSelect values={availability.times.map((t) => {
                            return {
                                key: t.time,
                                value: t.time + ' (' + t.tables.length + ' table' + (t.tables.length === 1 ? '' : 's') + ' left)'
                            }
                        })} prepend="Time" includeBlankOption={true} name="time" useSelect2={true}
                                           blankOptionText=" - Select Time - " selectedValue={booking.time}
                                           onChange={(e) => setBooking({...booking, time: e.target.value})}/>
                    </div>
                    {booking.time ? <>
                            <div className="col-md-6">
                                <FormElementSelect values={availableTables.map((t) => {
                                    return {
                                        key: t.id,
                                        value: t.name
                                    }
                                })} prepend="Table" includeBlankOption={true} name="table"
                                                   blankOptionText=" - Select Table - "
                                                   useSelect2={true}
                                                   selectedValue={booking.tables[0]}
                                                   onChange={(e) => setBooking({...booking, tables: [e.target.value]})}/>
                            </div>
                            <div className="col-md-6">
                                <FormElementText name="forename" value={booking.forename}
                                                 prepend="Forename *"
                                                 onChange={(e) => setBooking({
                                                     ...booking,
                                                     forename: e.target.value
                                                 })}/>
                            </div>
                            <div className="col-md-6">
                                <FormElementText name="surname" value={booking.surname}
                                                 prepend="Surname *"
                                                 onChange={(e) => setBooking({
                                                     ...booking,
                                                     surname: e.target.value
                                                 })}/>
                            </div>
                            <div className="col-md-6">
                                <FormElementText name="email" value={booking.email}
                                                 email={true}
                                                 prepend="E-mail *"
                                                 onChange={(e) => setBooking({
                                                     ...booking,
                                                     email: e.target.value
                                                 })}/>
                            </div>
                            <div className="col-md-6">
                                <FormElementText name="phone" value={booking.phone}
                                                 prepend="Phone *"
                                                 onChange={(e) => {
                                                     let value = e.target.value;
                                                     value = value.replace(/[^0-9+]/g, '');
                                                     setBooking({
                                                         ...booking,
                                                         phone: value
                                                     })
                                                 }}/>
                            </div>
                        </>
                        : null}
                </>}
            </> : null}
        </div>
    </div>
}

export default NewBooking;