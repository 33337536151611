import React, {useCallback, useContext, useEffect, useState} from 'react';
import Loading from "../Utility/Loading/Loading";
import Api from "../Api/Api";
import {ModalHelper} from "../Utility/Modal";
import Tabs from "../Utility/Tabs/Tabs";
import Tab from "../Utility/Tabs/Tab";
import {AppState} from "../Context/AppContext";
import FormElementText from "../Form/Element/Text";
import FormElementSelect from "../Form/Element/Select";
import dayjs from "dayjs";
import {ResState, timeDiffStringUnix} from "../Utility/ResState";
import Currency from "../Utility/Currency";
import BookingDetails from "./BookingDetails";
import cx from 'classnames';
import PreOrder from "./PreOrder";
import './Reservation.scss';
import Settings from "../Settings";
import EventDispatcher from "../Utility/EventDispatcher";

const Reservation = (props) => {
    const [loading, setLoading] = useState(true);
    const [smsSending, setSmsSending] = useState(false);
    const [showMessages, setShowMessages] = useState(false);
    const [tab, setTab] = useState('reservation');
    const [sms, setSms] = useState({
        error: null,
        sending: false,
        text: ''
    })
    const [warnings, setWarnings] = useState(null);
    const [reservation, setReservation] = useState({});
    const [state] = useContext(AppState);
    const saveReservation = useCallback((save_res) => {
        if (!save_res) {
            save_res = reservation;
        }
        Api.postJson('rest/res/reservation/' + props.reservation.id, save_res, (data) => {
            ModalHelper.closeModal();
        });
    }, [props.reservation.id, reservation]);

    const updateReservationData = useCallback(() => {
        Api.get('rest/res/reservation/' + props.reservation.id, {include: 'history'}, (data) => {
            let r = data.data;
            if (r.start_time_ts) {
                r.seconds_late = dayjs().unix() - r.start_time_ts;
            }
            setReservation(reservation => r);
            setLoading(false);
        })
    }, [props.reservation.id, setLoading, setReservation]);
    useEffect(() => {
        const listener = EventDispatcher.subscribe('reservation-updated', (data) => {
            if (data.id === props.reservation.id) {
                setLoading(true);
                updateReservationData();
                EventDispatcher.dispatch('show-notification', 'Reservation has been updated. Any unsaved changes have been removed');
            }
        });
        return () => {
            EventDispatcher.unsubscribe(listener);
        }
    }, [props.reservation, updateReservationData]);
    useEffect(() => {
        updateReservationData();
    }, [updateReservationData]);
    useEffect(() => {
        ModalHelper.addSaveButton(() => saveReservation());
    }, [saveReservation])
    useEffect(() => {
        if (reservation.start_time) {
            setWarnings(ResState.getWarningsForReservation(reservation));
        }
    }, [reservation]);
    if (loading) {
        return <Loading/>
    }
    let maxLength = state.settings.time_slot_length;
    let lengthValues = [];
    while (maxLength <= 800) {
        lengthValues.push({
            key: maxLength,
            value: timeDiffStringUnix(maxLength * 60, 0)
        })
        maxLength = maxLength + state.settings.time_slot_length;
    }
    let start = dayjs(reservation.start_time);
    let end = dayjs(reservation.end_time);
    let length = (end.diff(start) / 1000) / 60;
    let can_edit = state.device.read_only === false;
    let booking = reservation.is_booking ? reservation.booking.data : null;
    return <div className="reservation edit-reservation">
        {reservation.booking && reservation.booking.data && reservation.booking.data.balance > 0 ?
            <div className="alert alert-danger">
                This booking has an outstanding balance
                of {Api.currencyFormatter().format(reservation.booking.data.balance)}
            </div> : null}
        {warnings.length > 0 ? <div className="alert alert-danger">
            {warnings.map((w) => {
                return <p key={w.warning}>{w.warning}</p>
            })}
        </div> : null}
        {reservation.booking && reservation.allow_standing && <div className="alert alert-info">
            Customer has indicated they are OK with standing space
        </div>}
        <Tabs initial_tab={tab} handleChange={(tab) => {
            setTab(tab);
        }}>
            <Tab id="reservation" title="Reservation Details">
                <div className="res-info-wrapper">
                    <div className="res-input-group">
                        <div className="input-group covers">
                            <div className="covers-group">
                                <div className="btn-group">
                                    <label htmlFor="cover-numbers">Covers</label>
                                    {[1, 2, 3, 4, 5, 6, 7, 8].map((covers) => {
                                        return <button key={covers} data-covers="1" onClick={() => setReservation({
                                            ...reservation,
                                            covers: covers
                                        })}
                                                       disabled={!can_edit}
                                                       className={cx("btn", {active: covers === reservation.covers})}
                                                       name="cover-numbers">{covers}</button>
                                    })}
                                </div>
                                <div className="custom-covers">
                                    <label htmlFor="covers">Custom Covers</label>
                                    <FormElementText
                                        number={true}
                                        name="covers"
                                        disabled={!can_edit}
                                        value={reservation.covers}
                                        onBlur={(e) => setReservation({
                                            ...reservation,
                                            covers: parseInt(e.target.value)
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="res-duration">
                            <div className="res-start">
                                <FormElementSelect
                                    name="start_time"
                                    disabled={!can_edit}
                                    selectedValue={reservation.start_time}
                                    includeBlankOption={true}
                                    values={state.day_slots.map(function (t) {
                                        return {
                                            key: dayjs(t).format('YYYY-MM-DD HH:mm:ss'),
                                            value: dayjs(t).format('HH:mm')
                                        }
                                    })}
                                    onChange={(e) => {
                                        let djs = dayjs(e.target.value);
                                        setReservation({
                                            ...reservation,
                                            start_time: e.target.value,
                                            start_time_ts: djs.unix()
                                        })
                                    }}
                                    prepend="Start"
                                />
                            </div>
                            <div className="res-length">
                                <FormElementSelect
                                    name="length"
                                    selectedValue={parseInt(length)}
                                    includeBlankOption={false}
                                    values={lengthValues} disabled={!can_edit}
                                    onChange={(e) => {
                                        let time = dayjs(reservation.start_time).add(e.target.value, 'minute')
                                        setReservation({
                                            ...reservation,
                                            end_time: time.format('YYYY-MM-DD HH:mm:ss'),
                                            end_time_ts: time.unix()
                                        })
                                    }}
                                    prepend="Length"
                                />
                            </div>
                            <div className="res-status">
                                <FormElementSelect
                                    name="status_id"
                                    onChange={(e) => setReservation({
                                        ...reservation,
                                        status_id: parseInt(e.target.value)
                                    })}
                                    selectedValue={reservation.status_id}
                                    prepend="Status" disabled={!can_edit}
                                    includeBlankOption={true} blankOptionText=" - Select -"
                                    values={state.statuses.map(function (item) {
                                        return {
                                            key: item.id,
                                            value: item.name
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className="res-location">
                            <div className="res-tables">
                                <FormElementSelect
                                    name="table_ids"
                                    selectedValue={reservation.table_ids}
                                    values={state.tables.map(function (t) {
                                        return {
                                            key: t.id,
                                            value: t.name
                                        }
                                    })}
                                    disabled={!can_edit}
                                    useSelect2={true}
                                    onChange={(e) => {
                                        setReservation({...reservation, table_ids: e.target.value})
                                    }}
                                    multiple={true}
                                    prepend="Tables"
                                />
                            </div>
                            <div className="level-area">
                                <div className="res-levels">
                                    <FormElementSelect
                                        name="level_ids"
                                        selectedValue={reservation.level_ids}
                                        useSelect2={true}
                                        values={state.levels.map(function (t) {
                                            return {
                                                key: t.id,
                                                value: t.name
                                            }
                                        })}
                                        includeBlankOption={true}
                                        onChange={(e) => setReservation({...reservation, level_ids: e.target.value})}
                                        multiple={true} prepend="Levels" disabled={!can_edit}/>
                                </div>
                                <div className="res-areas">
                                    <FormElementSelect
                                        name="area_ids"
                                        selectedValue={reservation.area_ids}
                                        useSelect2={true}
                                        values={state.areas.map(function (a) {
                                            return {
                                                key: a.id,
                                                value: a.name
                                            }
                                        })}
                                        includeBlankOption={true}
                                        onChange={(e) => setReservation({...reservation, area_ids: e.target.value})}
                                        multiple={true} prepend="Areas" disabled={!can_edit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="reservation-info">
                        <div className="res-info-header">
                            <div className="">
                                <i className="fa fa-user"/> <span>{reservation.covers}</span>
                            </div>
                            <div className="">
                                <span>{dayjs(reservation.start_time).format('HH:mm')} - {dayjs(reservation.end_time).format('HH:mm')} ({timeDiffStringUnix(length * 60, 0)})</span>
                            </div>
                            {reservation.food_time ?
                                <div className=" "><span>Food: {dayjs(reservation.food_time).format('HH:mm')}</span>
                                </div> : null}
                        </div>
                        <div className="res-info-box">
                            <div className="tables">
                                <p>Table{reservation.table_ids.length === 1 ? '' : 's'}:</p>
                                {reservation.table_ids.length > 0 ?
                                    <div className="tables-content">
                                        {reservation.table_ids.map((table_id) => {
                                            let table = ResState.getTableById(table_id);
                                            return <span key={table_id}>{table.name}</span>;
                                        })}
                                    </div> : null}
                            </div>
                            <div className="levels">
                                <p>Level{reservation.level_ids.length === 1 ? '' : 's'}:</p>
                                {reservation.level_ids.length > 0 ?
                                    <div className="levels-content">
                                        {reservation.level_ids.map((level_id) => {
                                            let level = ResState.getLevelById(level_id);
                                            return <span key={level_id}>{level.name}</span>;
                                        })}
                                    </div> : null}
                            </div>
                            <div className="areas">
                                <p>Area{reservation.area_ids.length === 1 ? '' : 's'}:</p>
                                {reservation.area_ids.length > 0 ?
                                    <div className="areas-content">
                                        {reservation.area_ids.map((area_id) => {
                                            let area = ResState.getAreaById(area_id);
                                            return <span key={area_id}>{area.name}</span>;
                                        })}
                                    </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reservation-actions">
                    <div className=" reservation-message-send">
                        {reservation.can_send_sms ?
                            <div>
                                <div className={cx("btn-group", {open: showMessages})}>
                                    <button type="button"
                                            onClick={() => {
                                                setShowMessages(!showMessages);
                                            }}

                                            className={"btn dropdown-toggle " + (sms.error ? 'btn-danger' : 'btn-info')}
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                                            disabled={sms.sending}>
                                        {sms.sending ? 'Sending....' : 'Send Message'} <span className="caret"/>
                                    </button>
                                    <ul className="dropdown-menu">
                                        {state.message_templates.length === 0 ?
                                            <li>No Messages Available</li> : null}
                                        {state.message_templates.map((m) => {
                                            return <li key={m.id}>
                                                <button
                                                    disabled={smsSending}
                                                    aria-disabled={smsSending}
                                                    onClick={() => {
                                                        setSmsSending(true);
                                                        setSms({
                                                            sending: true,
                                                            message: m.id,
                                                            error: null
                                                        });
                                                        setShowMessages(false);
                                                        Api.post('rest/res/reservation/' + reservation.id + '/send-message', {message_id: m.id}, (data) => {
                                                            setSms({
                                                                sending: false,
                                                            });
                                                            EventDispatcher.dispatch('show-notification', 'Message has been sent');
                                                        }, (xhr, status, err, data, errorMessage) => {
                                                            setSms({
                                                                sending: false,
                                                                message: null,
                                                                error: true
                                                            })
                                                            EventDispatcher.dispatch('show-error', errorMessage);
                                                        });
                                                    }}>{m.content}
                                                </button>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className="btn-group">
                        {reservation.status.data.id === state.settings.status_upcoming ?
                            <button className="btn seat-reservation" onClick={() => {
                                let save_res = {...reservation, status_id: state.settings.status_seated};
                                setReservation(save_res)
                                saveReservation(save_res);
                            }}>Seat Reservation</button> : null}

                        {reservation.status.data.id === state.settings.status_upcoming && reservation.seconds_late > (60 * 10) ?
                            <button className="btn no-show" onClick={() => {
                                let save_res = {...reservation, status_id: state.settings.status_no_show};
                                setReservation(save_res)
                                saveReservation(save_res);
                            }}>No Show Reservation</button> : null}
                        {reservation.status.data.id === state.settings.status_finalised ?
                            <button className="btn complete-reservation"
                                    onClick={() => {
                                        let save_res = {
                                            ...reservation,
                                            status_id: state.settings.status_finished
                                        };
                                        setReservation(save_res)
                                        saveReservation(save_res);
                                    }}>Complete Reservation</button> : null}
                    </div>
                </div>
                {reservation.status_id === 90 && booking && booking.no_show_fee && booking.has_stored_card ?
                    <div className="alert alert-danger clearfix">
                        By marking this booking as a no show the customer WILL BE charged the no show fee
                        of {Currency.format(booking.no_show_fee)}
                    </div> : null}
            </Tab>
            {booking ? <Tab id="booking" title="Booking Details">
                <BookingDetails booking={booking}/>
            </Tab> : <Tab id="walkin" title="Walk in Details">
                <FormElementText name="walkin_name" value={reservation.walkin_name} prepend="Name"
                                 onChange={(e) => {
                                     let save_res = {...reservation, walkin_name: e.target.value};
                                     setReservation(save_res);
                                 }}/>
                <FormElementText name="walkin_mobile" value={reservation.walkin_mobile} prepend="Mobile"
                                 onChange={(e) => {
                                     let save_res = {...reservation, walkin_mobile: e.target.value};
                                     setReservation(save_res);
                                 }}/>
            </Tab>}
            {booking && booking.value > 0 ? <Tab id="pre_order" title="Pre Order">
                <PreOrder booking={booking}/>
            </Tab> : null}
            <Tab id="history" title="History">
                <div className="table-wrap">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Source</th>
                            <th>Entry</th>
                            <th>From Till</th>
                        </tr>
                        </thead>
                        <tbody>
                        {reservation.history.data.map((h) => {
                            return <tr key={h.id}>
                                <td>{dayjs(h.date).format('DD/MM/YYYY HH:mm:ss')}</td>
                                <td>{h.display_name}</td>
                                <td>{h.comment}</td>
                                <td>{h.from_epos ? <i className="fa fa-check"/> : <i className="fa fa-times"/>}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            </Tab>
            {Settings.debug ? <Tab id="debug" title="Debug">
                <div style={{maxHeight: '400px', overflow: 'scroll'}}>
                <pre>
                    {JSON.stringify(reservation, null, 2)}
                </pre>
                </div>
            </Tab> : null}
        </Tabs>

    </div>
}

export default Reservation;