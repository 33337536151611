import React, {useState} from 'react';

const Collapse = (props) => {
    const [open, setOpen] = useState(true);
    
    let headerBgColour = open ? {backgroundColor: '#337ab7'} : {backgroundColor: '#41a5f0'}; 
    return <div className="collapse-container">
        <div style={headerBgColour} className="collapse-header" onClick={() => setOpen(!open)}>
            {props.header}
            <a href="#">
                <i className={open ? "fa fa-angle-down" : 'fa fa-angle-up'}/>
            </a>
        </div>
        <div className="collapse-content" style={{display: open ? 'block' : 'none'}}>
            {props.children}
        </div>
    </div>
}

export default Collapse;