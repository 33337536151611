import React, {useContext, useEffect, useState} from 'react';
import FormElementText from "../Form/Element/Text";
import {AppState} from "../Context/AppContext";
import EventDispatcher from '../Utility/EventDispatcher';
import dayjs from 'dayjs';
import './BookingSearch.scss'

const BookingSearch = (props) => {
    const [search, setSearch] = useState('');
    const [placeholder, setPlaceholder] = useState('booking search . . .')
    const [show, setShow] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [state] = useContext(AppState);
    useEffect(() => {
        setBookings(state.reservations.filter((res) => {
            return res.display_name.toLowerCase().includes(search.toLowerCase());
        }))
    }, [search, state.reservations])

return <div className="booking-search">
        <i className="fas fa-search"></i>
        <FormElementText 
            name="search" 
            value={search} 
            onChange={(e) => setSearch(e.target.value)}          
            placeholder={placeholder} 
            onFocus={(e) =>{
                setShow(true)
                setPlaceholder('')
            }} 
            onBlur={() => {
                setTimeout(() => {
                    setSearch('')
                    setShow(false)
                    setPlaceholder('bookings search . . .')
                }, 200);
               
            }}/>

        {show && search.length > 0 ? 
        <ul className="search-results ipad-scroll">
            {bookings.map(function (res) {
            return <li className="search-group" key={res.id} onClick={(e) => {
                EventDispatcher.dispatch('open-reservation', res);
            }}>
                <p className="search-covers">{res.covers} <i className="far fa-user"/></p>
                <p className="search-time">Booking Time: {dayjs(res.start_time).format('HH:mm')}</p>
                <div className="search-tables" 
                    style={res.reserved_spaces.length <= 1 ? 
                    {justifyContent: 'center'} : 
                    {justifyContent: 'space-between'}}>
                    {res.reserved_spaces.map((table, idx)=> (<p key={idx}>{table}</p>))}
                </div>
                <p className="search-name">{res.display_name}</p>
            </li>
            })}
        </ul> 
        : null}
    </div>
}

export default BookingSearch;