import React from 'react';
import EventDispatcher from "../Utility/EventDispatcher";

const ListViewLevelTable = React.memo((props) => {
    let time = props.slot.time_time;
    let time_id = props.slot.id;
    let t = props.table;
    let headerTimes = ['07:00', '09:00', '11:00', '13:00', '15:00', '17:00', '19:00', '21:00', '23:00', '01:00', '03:00', '05:00'];
    let show_name = headerTimes.indexOf(time) > -1;
    return <div className={"table-cell-wrapper " + (show_name ? 'has-name' : '')}
                key={t.table_id + '_' + time_id}>
        {show_name ? <div className="table-name"
                          title={t.table.seats + ' seats'}>{t.table.name}</div> : null}
        <div className={"droppable table-time time-slot-" + time_id}
             id={'list_view_' + t.table.id + '_slot_' + time_id}
             onClick={() => {
                 EventDispatcher.dispatch('add-walk-in', {
                     start_time: props.slot.time,
                     tables: [t.table_id],
                     covers: 4,
                     status_id: '10',
                 });
             }}
             data-table={t.table.id} data-time={time}>{time}</div>
    </div>
});

export default ListViewLevelTable;