import React, {useContext, useEffect, useState} from 'react';
import {AppState} from "../Context/AppContext";
import dayjs from "dayjs";
import Collapse from "../Utility/Collapse";
import ReservationRow from "./ReservationRow";
import EventDispatcher from "../Utility/EventDispatcher";
import {ModalHelper} from "../Utility/Modal";
import NewBooking from "../NewBooking/NewBooking";
import './RightColumn.scss'
import Api from "../Api/Api";
import UnassignedBookingRow from "./UnassignedBookingRow";

const RightColumn = (props) => {
    const [state] = useContext(AppState);
    const [show, setShow] = useState({
        food: true,
        party: true
    });
    const [bookingTypeCounts, setBookingTypeCounts] = useState([]);
    const [upcoming, setUpcoming] = useState([]);
    const [unassignedBookings, setUnassignedBookings] = useState([]);
    useEffect(() => {
        let res = state.reservations.filter((r) => {
            return r.status_id === 10;
        });
        if (show.party === false) {
            res = res.filter((r) => {
                return r.is_party_booking === false;
            });
        }
        if (show.food === false) {
            res = res.filter((r) => {
                return r.is_food_booking === false;
            });
        }
        setUpcoming(res.sort((res1, res2) => {
            if (res1.start_time_ts === res2.start_time_ts) {
                return res1.id > res2.id ? 1 : -1;
            }
            return res1.start_time_ts > res2.start_time_ts ? 1 : -1;
        }));
    }, [state.reservations, show]);
    useEffect(() => {
        setUnassignedBookings(state.bookings.filter((b) => {
            let res = state.reservations.find((r) => {
                return r.booking_id === b.id;
            });
            return !!res;
        }));
        let counts = {};
        if (state.reservations.length === 0) {
            setBookingTypeCounts([]);
        }
        state.reservations.map((r) => {
            if (!counts[r.booking_type_name]) {
                counts[r.booking_type_name] = {
                    bookings: 0,
                    covers: 0,
                    pbr: 0
                }
            }
            counts[r.booking_type_name].bookings = counts[r.booking_type_name].bookings + 1;
            counts[r.booking_type_name].covers = counts[r.booking_type_name].covers + r.covers;
            counts[r.booking_type_name].pbr = counts[r.booking_type_name].pbr + r.pbr;

            let typeStats = Object.keys(counts).map((type) => {
                return {
                    type: type,
                    counts: counts[type]
                }
            }).sort((t1, t2) => {
                return t1.counts.bookings > t2.counts.bookings
            });
            setBookingTypeCounts(typeStats);
            return r;
        });
    }, [state.bookings, state.reservations]);
    return <div className="right-col" style={{height: (state.screen.base_height * state.screen.ratio) + 100}}>
        {!state.time_travel ?
            <Collapse header={"Next free tables " + dayjs().format('ddd Do MMM')}>
                <ul className="next-slots">
                    {state.walkins.map((walkin) => {
                        return <li className="available-slot" key={walkin.covers}>
                            <button className="btn btn-default res-info" onClick={(e) => {
                                e.preventDefault();
                                let diff = Math.abs(dayjs().unix() - walkin.slot.slot.time_ts);
                                let status = 20;
                                if (diff > (60 * 15)) {
                                    status = 10;
                                }
                                EventDispatcher.dispatch('add-walk-in', {
                                    start_time: walkin.slot.slot.time,
                                    tables: [walkin.slot.table.id],
                                    covers: walkin.covers,
                                    status_id: status,
                                });
                            }}>
                                <div className="free-tables-group">
                                    <span className="covers">{walkin.covers} <i className="far fa-user"/></span>
                                    <span className="time">
                                        {walkin.slot.slot.time_time}
                                    </span>
                                    <span className="time-diff">
                        <i className="far fa-clock"/> {dayjs(walkin.slot.slot.time).fromNow(true)}
                    </span>
                                    <span className="tables">T
                                        {walkin.slot.table.name}
                                    </span>
                                </div>
                            </button>
                        </li>
                    })}
                </ul>
            </Collapse> : <>
                <div className="bookings-summary">
                    {state.reservations.length === 0 ? <>
                        <p style={{textAlign: 'center'}}>You don't have any bookings at all
                            on {dayjs(state.start).format('dddd Do MMMM')} 🙁
                        </p>
                    </> : <>
                        <h5 className="bookings-date">{dayjs(state.start).format('dddd Do MMMM')}</h5>
                        <p className="bookings-title">{`You have ${state.reservations.length} booking${state.reservations.length === 1 ? '' : 's'}`}</p>
                        <hr/>
                        <div className="booking-content">
                            {bookingTypeCounts.map((t) => {
                                return <div key={t.type}>
                                    <p className="booking-type">{t.type}</p>
                                    <p>{t.counts.bookings} Booking{t.counts.bookings === 1 ? '' : 's'}</p>
                                    <p>{t.counts.covers} Cover{t.counts.covers === 1 ? '' : 's'}</p>
                                    <p>{t.counts.pbr > 0 ? <>&nbsp;({Api.currencyFormatter().format(t.counts.pbr)} PBR)</> : null}</p>
                                </div>
                            })}
                        </div>
                    </>}
                </div>
                <div className="create-booking">
                    <button className="btn btn-success" onClick={() => {
                        ModalHelper.openModal('Add New Booking', <NewBooking/>);
                    }}>Create Booking
                    </button>
                </div>
            </>}
        <Collapse header={(state.time_travel ? 'Future ' : 'Upcoming ') + 'Reservations (' + upcoming.length + ')'}>
            <div className="upcoming-res-options">
                <span className="show">Filter:</span>
                <button className={'btn btn-' + (show.food ? 'success' : 'default')}
                        onClick={() => {
                            setShow({...show, food: !show.food})
                        }}>Food
                </button>
                <button className={'btn btn-' + (show.party ? 'success' : 'default')}
                        onClick={() => {
                            setShow({...show, party: !show.party})
                        }}>Party
                </button>
            </div>
            {upcoming.length === 0 ?
                <div className="well" style={{textAlign: 'center'}}><strong>No upcoming bookings</strong>
                </div> : null}
            <ul className="next-slots upcoming-res">
                {upcoming.map(function (res) {
                    return <ReservationRow reservation={res} key={res.id}/>
                })}
            </ul>
        </Collapse>
        <Collapse header={'Unassigned Bookings (' + unassignedBookings.length + ')'}>
            <ul className="next-slots">
                {state.bookings.map((booking) => {
                    return <UnassignedBookingRow booking={booking} key={booking.booking_id}/>
                })}
            </ul>
        </Collapse>
    </div>
}
export default RightColumn;