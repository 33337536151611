import {useContext, useState} from 'react';
import Api from "../Api/Api";
import FormElementText from "../Form/Element/Text";
import {AppState} from "../Context/AppContext";
import * as localforage from "localforage";
import FormElementBarSelect from "../Form/Element/BarSelect";

export default function AuthDevice(props) {
    const [loading, setLoading] = useState(false);
    const [state, setState] = useContext(AppState);
    const [details, setDetails] = useState({
        bar_id: '',
        name: '',
        comment: ''
    });
    const handleSave = (e) => {
        e.preventDefault();
        setLoading(true);
        Api.post('rest/res/device', {
            bar_id: details.bar_id,
            name: details.name,
            comment: details.comment,
            device_id: state.settings.device_id
        }, (data) => {
            setLoading(false);
            setSaved(true);
            setDetails({
                bar_id: '',
                name: '',
                comment: ''
            })
            localforage.setItem('token', data.data.token).then(() => {
                setState({
                    type: 'SET_TOKEN',
                    payload: data.data.token
                });

            });
        });
    }
    const [saved, setSaved] = useState(false);
    if (loading) {
        return <p>Loading...</p>
    }
    if (state.settings.device_id && state.settings.token) {
        return <div className="authentication" style={{margin: '50px'}}>
            <div className="row">
                <div className="col-md-12">
                    <h2>This device is not authorised</h2>
                </div>
                <div className="col-md-6">
                    <div className="alert alert-warning">
                        This device is waiting to be authorised. Go into "Table Bookings" section of R-Cloud to approve.
                        <p>Device ID: <strong>{state.settings.device_id}</strong></p>
                        <button className="btn btn-danger" onClick={() => {
                            localforage.removeItem('device_id').then(() => {
                                localforage.removeItem('token').then(() => {
                                    document.location.reload();
                                })
                            });
                        }}>Reset authorisation
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }
    return <div className="authentication" style={{margin: '50px'}}>
        <div className="row">
            <div className="col-md-12">
                <h2>This device is not authorised</h2>
            </div>
            <div className="col-md-12">
                <p>To authorise this device chose the bar from below. Make sure the device name is as descriptive as
                    possible. Devices which don't have descriptive names will be removed periodically</p>
            </div>
            {saved ? <div className="col-md-6">
                <div className="alert alert-success">Device details have been saved. Log in to R-Cloud and approve
                    in the
                    "Table Bookings" section. This page will automatically reload when the device has been
                    authorised
                </div>
            </div> : null}
        </div>
        <div className="row">
            <div className="col-md-6">
                <FormElementText name="device_id" value={state.settings.device_id} disabled={true}
                                 prepend="Device ID"/>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <FormElementText name="name" value={details.name}
                                 onChange={(e) => setDetails({...details, name: e.target.value})}
                                 prepend="Name"/>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <FormElementBarSelect name="bar_id" onChange={(e) => setDetails({...details, bar_id: e.target.value})}
                                      selectedValue={details.bar_id} prepend="Bar" includeBlankOption={true}
                                      blankOptionText=" - Select - "
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <FormElementText name="comment" value={details.comment}
                                 onChange={(e) => setDetails({...details, comment: e.target.value})}
                                 prepend="Comment"/>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <button className="btn btn-success" onClick={(e) => handleSave(e)}>Save</button>
            </div>
        </div>
    </div>
}