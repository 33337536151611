import React, {useContext, useEffect, useState} from 'react';
import {AppState} from "../Context/AppContext";
import LayoutDetail from "./LayoutDetail";
import EventDispatcher from "../Utility/EventDispatcher";

const Level = React.memo((props) => {
    const [state] = useContext(AppState);
    const [tables, setTables] = useState([]);
    let updating = false;
    useEffect(() => {
        if (state.active_layout) {
            setTables(state.active_layout.detail.data.filter((d) => {
                return d.level_id === props.level.id;
            }));
        }
    }, [state.active_layout, props.level]);
    if (!state.active_layout) {
        return <span/>
    }
    return <div className="level" style={{width: state.screen.base_width * state.screen.ratio + 'px'}} onClick={() => {
        EventDispatcher.dispatch('close-tables');
    }}>
        <img src={props.level.image} style={{opacity: 0.5}} alt={props.level.name}/>
        {updating ? <div style={{width: state.screen.base_width * state.screen.ratio + 'px'}}
                         className="level-loading"/> : null}
        {tables.map((detail) => {
            return <LayoutDetail detail={detail} key={detail.id}/>

        })}
    </div>
});
export default Level;