import React, {useEffect} from 'react';
import EventDispatcher from "../Utility/EventDispatcher";
import Api from "../Api/Api";
import $ from 'jquery';

const ListViewReservation = (props) => {
    let res = props.reservation;
    let table = props.table;
    let pos = props.positions[table + '-' + res.first_slot];
    useEffect(() => {
        let el = $('#list_view_booking_' + props.reservation.id);
        if (el) {
            el.draggable({
                handle: '.drag',
                helper: 'clone',
                snap: '.droppable',
                axis: 'y',
                stop: (event, ui) => {
                    let pos = ui.position;
                    let min_top = pos.top - 2;
                    let max_top = pos.top + 2;
                    let min_left = pos.left - 2;
                    let max_left = pos.left + 2;
                    let found_key = null;
                    Object.keys(props.positions).map((key) => {
                        var p = props.positions[key].pos;
                        if (p.left > min_left && p.left < max_left && p.top > min_top && p.top < max_top) {
                            found_key = key;
                        }
                        return key;
                    });
                    if (found_key) {
                        var parts = found_key.split('_');
                        var new_table = parts[0];
                        Api.post('rest/res/reservation/' + props.reservation.id + '/tables', {
                            old_table: props.table,
                            new_table: new_table
                        }, function (data) {

                        });
                    }
                }
            });
        }
    }, [props.reservation, props.table, props.positions, props.reservation.id]);
    let end_pos = props.positions[table + '-' + res.last_slot];
    if (!pos || !end_pos || !pos.pos || !end_pos.pos) {
        return null;
    }
    let style = {};
    if (res.status_id) {
        if (!res.status_colour) {
            style.background = '#41a5f0';
        } else {
            style.background = '#' + res.status_colour;
        }
        if (res.status_text_colour) {
            style.color = '#' + res.status_text_colour;
        }
    }
    style.width = (end_pos.pos.left - pos.pos.left) + 'px';
    style.top = pos.pos.top;
    style.left = pos.pos.left;
    style.height = pos.height;
    style.display = 'block';
    style.zIndex = 1;
    return <div className={"list-view-booking status-" + res.status_id} data-table={table}
                style={style}
                data-reservation-id={res.id}
                id={'list_view_booking_' + res.id}
                onClick={(e) => {
                    EventDispatcher.dispatch('open-reservation', res);
                }}>
        <i className="fa fa-bars drag"/>
        <span
            data-reservation-id={res.id} className="res-info">{res.display_name} <span
            style={{float: 'right'}}>{res.covers} <i
            className="fa fa-user"/></span></span>
    </div>
}

export default ListViewReservation;