import React, {Fragment} from 'react';
import Api from "../Api/Api";


const PreOrder = (props) => {
    const booking = props.booking;
    return <div className="pre-order">
        {booking.options.data.map(function (opt) {
            return <Fragment key={opt.id}>
                <strong>{opt.title}</strong>
                {opt.pre_order_flat.length === 0 ?
                    <div className="alert alert-warning">No pre order has been made for this option</div> : <>

                        {opt.price_per_booking ?
                            <table className="table table-bordered table-striped">
                                <thead>
                                <tr>
                                    {opt.pre_order_flat[0].choices.map((c) => {
                                        return <th key={c.name}>{c.name}</th>
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {opt.pre_order_flat.map((o) => {
                                    return <tr>
                                        {o.choices.map((c) => {
                                            return <td
                                                key={c.option}>{c.option} {c.premium > 0 ?
                                                <strong>+({Api.currencyFormatter().format(c.premium)})</strong> : null}</td>
                                        })}
                                    </tr>
                                })}
                                </tbody>
                            </table> :
                            <table className="table table-bordered table-striped">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    {opt.pre_order_flat[0].choices.map((c) => {
                                        return <th key={c.name}>{c.name}</th>
                                    })}
                                </tr>
                                {opt.pre_order_flat.map((o) => {
                                    return <tr>
                                        <td>{o.name}</td>
                                        {o.choices.map((c) => {
                                            return <td
                                                key={c.option}>{c.option} {c.premium > 0 ?
                                                <strong>+({Api.currencyFormatter().format(c.premium)})</strong> : null}</td>
                                        })}
                                    </tr>
                                })}
                                </thead>
                            </table>}
                    </>}
            </Fragment>
        })}
    </div>
}

export default PreOrder;