import React, {useContext} from 'react';
import cx from 'classnames';
import {AppState} from "../Context/AppContext";
import * as dayjs from "dayjs";

const ChatEntry = (props) => {
    let chat = props.chat;
    const [state] = useContext(AppState);
    return <div className={cx('chat-entry', {'current-user': chat.device_id === state.device.id})}>
        <div className="chat-entry---message">{chat.message}</div>
        <div className="chat-entry--name">
            {chat.device_id ? <i className="fa fa-desktop"/> : <i className="far fa-user"/>}
            {chat.device_id ? chat.device_name : chat.user_name} - {dayjs(chat.date).format('HH:mm')}
        </div>
    </div>
}

export default ChatEntry;