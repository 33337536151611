import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import * as localforage from "localforage";


localforage.config({
    name: 'RHost',
    storeName: 'device'
});
Sentry.init({
    dsn: "https://db955ef712c64af285e062c165e6f8c7@sentry-relay.revbars.cloud/9",
    integrations: [new Integrations.BrowserTracing()],
    environment: `${process.env.REACT_ENV}`,
    release: `${process.env.REACT_APP_RELEASE}`,
    tracesSampleRate: 0.2,
});
// ReactDOM.createRoot(document.getElementById('root')).render(<App/>);
ReactDOM.render(<App/>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from './reportWebVitals';
// reportWebVitals(console.log);
