import React, {useContext, useEffect, useState} from 'react';
import {AppState} from "../Context/AppContext";
import Currency from "../Utility/Currency";
import dayjs from "dayjs";
import EventDispatcher from "../Utility/EventDispatcher";
import cx from 'classnames';

const RunList = () => {
    const [state] = useContext(AppState);
    const [reservations, setReservations] = useState([]);
    const [type, setType] = useState('active');
    const [statuses, setStatuses] = useState([20, 24, 28, 32, 36, 38, 50]);
    const setActiveType = (type) => {
        setType(type);
        if (type === 'upcoming') {
            setStatuses([10]);
        }
        if (type === 'active') {
            setStatuses([20, 24, 28, 32, 36, 38, 50, 60]);
        }
        if (type === 'finished') {
            setStatuses([70]);
        }
        if (type === 'no-show') {
            setStatuses([90]);
        }
    }
    useEffect(() => {
        setReservations(state.reservations.filter((r) => {
            return statuses.indexOf(r.status_id) > -1;
        }))
    }, [statuses, state.reservations]);
    return <div className="run-list"  style={{height: (state.screen.base_height * state.screen.ratio) + 50}}>
        <div className="btn-group">
            <button
                className={cx('btn', {'btn-primary': type === 'upcoming'}, {'btn-default': type !== 'upcoming'})}
                onClick={() => setActiveType('upcoming')}>Upcoming
            </button>
            <button
                className={cx('btn', {'btn-primary': type === 'active'}, {'btn-default': type !== 'active'})}
                onClick={() => setActiveType('active')}>Active
            </button>
            <button
                className={cx('btn', {'btn-primary': type === 'finished'}, {'btn-default': type !== 'finished'})}
                onClick={() => setActiveType('finished')}>Finished
            </button>
            <button className={cx('btn', {'btn-primary': type === 'no-show'}, {'btn-default': type !== 'no-show'})}
                    onClick={() => setActiveType('no-show')}>No
                Show
            </button>
        </div>
        <div className="table-wrap">
        <table className="table">
            <thead>
            <tr>
                <th>Time</th>
                <th>Seated Time</th>
                <th>Tables / Areas</th>
                <th>Covers</th>
                <th>Customer Name</th>
                <th>PBR / Deposit</th>
                <th>Pre Booked Options</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {reservations.map(function (res) {
                //let walkin = res.walkin.data.id ? res.walkin.data.id : null;
                let pbrDeposit = <span>N/A</span>;
                if (res.pbr > 0) {
                    pbrDeposit =
                        <span>{Currency.format(res.pbr)} / {Currency.format(res.deposit_total)}</span>
                }
                return <tr onClick={() => {
                    EventDispatcher.dispatch('open-reservation', res);
                }} key={res.id}>
                    <td className="table-time">
                        <span>{dayjs(res.start_time).format('HH:mm')}</span> 
                        <span className="time-divider">-</span> 
                        <span>{dayjs(res.end_time).format('HH:mm')}</span>
                    </td>
                    <td>{res.actual_start_time ? dayjs(res.actual_start_time).format('HH:mm') : 'N/A'}</td>
                    <td>{res.reserved_spaces.join(', ')}</td>
                    <td>{res.covers}</td>
                    <td>{res.display_name}</td>
                    <td>{pbrDeposit}</td>
                    <td>{res.options.join(', ')}</td>
                    <td>{res.status_name}</td>
                </tr>
            })}
            </tbody>
        </table> 
        </div>
        
        {!reservations.length > 0 && <div className="empty-list"><span>nothing to display...</span></div>}
    </div>
}

export default RunList;