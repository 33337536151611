import PropTypes from 'prop-types';
import cx from 'classnames';
import {useEffect, useState} from 'react';
import {Select2} from "select2-react-component";
import 'select2-component/dist/select2.css';

export default function FormElementSelect(props) {
    let [value, setValue] = useState(props.selectedValue);
    let className = props.className;
    if (!className) {
        className = 'form-control';
    }
    let id = props.id;
    if (!id) {
        id = props.name;
    }
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    let element = null;
    if (props.useSelect2 === true) {
        element = <Select2 multiple={props.multiple} update={(e) => {
            if (props.onChange) {
                props.onChange({
                    target: {
                        name: props.name,
                        value: e
                    }
                })
            }
        }} value={props.selectedValue} data={props.values.map((v) => {
            return {
                value: v.key,
                label: v.value
            }
        })}/>
    } else {
        element = <select name={props.name} id={id} value={value}
                          className={cx(className, {'is-invalid': props.error})}
                          onChange={(e) => {
                              if (props.onChange) {
                                  props.onChange(e);
                              }
                          }}
                          multiple={props.multiple}
                          disabled={props.disabled}
                          onBlur={props.onBlur} placeholder={props.placeholder}
                          onFocus={props.onFocus}>
            {props.includeBlankOption === true ? <option value="">{props.blankOptionText}</option> : null}
            {props.values.map(function (option) {
                if (option.disabled) {
                    return <option value={option.key} key={option.key} disabled>{option.value}</option>
                }
                return <option value={option.key} key={option.key}>{option.value}</option>
            })}
        </select>
    }
    return <div className={cx(props.append ? 'input-group' : 'form-group')}>
        <label htmlFor={props.name}>{props.prepend}</label>
        {element}
        {props.append ? <div className="input-group-addon">{props.append}</div> : null}
    </div>
}

FormElementSelect.propTypes = {
    selectedValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.array,
    ]),
    values: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    defferredUpdate: PropTypes.bool,
    number: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    maxLength: PropTypes.number,
    error: PropTypes.bool
};