import React, {useContext} from 'react';
import Tabs from "../Utility/Tabs/Tabs";
import Tab from "../Utility/Tabs/Tab";
import TimeDrag from "../Form/Element/TimeDrag";
import Level from "./Level";
import {AppState} from "../Context/AppContext";
import RunList from "../RunList/RunList";
import ListView from "../ListView/ListView";
import Settings from "../Settings";

const Layout = (props) => {
    const [state] = useContext(AppState);
    let initial_tab = state.levels.length > 0 ? state.levels[0].id : '';
    return <>
        <div className="layout">
            <Tabs initial_tab={initial_tab}>
                {state.levels.filter((level) => {
                    return level.hide === false;
                }).map((level) => {
                    return <Tab id={level.id} title={level.name} key={level.id}>
                        <Level level={level}/>
                        <TimeDrag name={'time'}/>
                    </Tab>
                })}
                <Tab id="list_view" title="List View" float="right">
                    <ListView/>
                </Tab>
                <Tab id="run_list" title="Run List" float="right">
                    <RunList/>
                </Tab>
            </Tabs>
        </div>
    </>
}
export default Layout;