import PropTypes from 'prop-types';
import cx from 'classnames';
import {useEffect, useState} from 'react';
import Api from "../../Api/Api";
import Loading from "../../Utility/Loading/Loading";

export default function FormElementBarSelect(props) {
    let [value, setValue] = useState(props.value);
    let [bars, setBars] = useState([]);
    let [loading, setLoading] = useState(true);

    let className = props.className;
    if (!className) {
        className = 'form-control';
    }
    let id = props.id;
    if (!id) {
        id = props.name;
    }
    useEffect(() => {
        Api.get('https://rbg-public-data.s3-eu-west-1.amazonaws.com/bar-data.json', {}, (data) => {
            setBars(data.filter(function (b) {
                return b.open && !b.central_bar;
            }));
            setLoading(false);
        })
    }, []);
    return <div className={cx('input-group')}>
        {loading ? <Loading/> : <>
            {props.prepend ? <div className="input-group-addon">{props.prepend}</div> : null}
            <select name={props.name} id={id} value={value}
                    className={cx(className, {'is-invalid': props.error})}
                    onChange={(e) => {
                        setValue(e.target.value);
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                    disabled={props.disabled}
                    onBlur={props.onBlur} placeholder={props.placeholder}
                    onFocus={props.onFocus}>
                {props.includeBlankOption === true ? <option value="">{props.blankOptionText}</option> : null}
                {bars.map(function (option) {
                    if (option.disabled) {
                        return <option value={option.id} key={option.id} disabled>{option.name}</option>
                    }
                    return <option value={option.id} key={option.id}>{option.name}</option>
                })}
            </select>
            {props.append ? <div className="input-group-addon">{props.append}</div> : null}
        </>}
    </div>
}

FormElementBarSelect.propTypes = {
    selectedValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    defferredUpdate: PropTypes.bool,
    number: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    maxLength: PropTypes.number,
    error: PropTypes.bool
};